import React from "react"
import { useState, useEffect, createContext } from "react"
import { Container } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useSanctum } from "../sanctum/sanctum"
import { ElpFlat } from "./elp-flat"
import { Bilingue_t } from "../utilities/bilingue"
import { LigneBilingueFlat } from "./bilingue-flat"
import { ChargesElp_t, ChargesEtp_t, LicNel_t } from "../apogee/apogee-types"
import { CplDuree_t, Libelle_t, MapModEvalComp_t, MapNiveauxComp_t } from "../syllabus/syllabus-types"

/**
 * SylParapheFlat_t, SylBiParapheFlat_t
 * 
 * atributs communs à tous les objets du schéma syllabus de la base de données
 * SylParapheFlat_t est commun à tous les objets directement en relation avec ElpSyl
 * et modifiables.
 * SylBiParapheFlat_t est commun à tous les objets modifiables organisés en listes.
 * 
 */
interface SylParapheFlat_t {
	validite: string
	updated_at: Date
	updated_by?: string
}

interface SylBiParapheFlat_t {
	validite: string
	updated_at: {
		obj: Date
		pivot: Date
	}
	updated_by: {
		obj?: string
		pivot?: string
	}
}
// Définition de EcoleFlat_t

type DiplomeFlat_t = {
	code: string
	codVdi: number
	lic: string
	licVdi: string
}

type ParcoursFlat_t = {
	code: string
	nom: string | null
	lic: Bilingue_t
	desc?: Bilingue_t
}

type MapBlocsComp_t = {
	[numFrComp: string] : {
		numFrComp: string
		appellation: Bilingue_t
	}
}

export interface FactuelFlat_t  extends SylBiParapheFlat_t {
	commentaire?: string
	libelle?: Libelle_t
	cplDuree?: CplDuree_t
	anglais: boolean
}

export interface ModaliteFlat_t  extends SylParapheFlat_t {
	anglais?: boolean
}

export interface NoticeElpFlat_t  extends SylParapheFlat_t {
	description?: {
		contexte?: Bilingue_t
		contenu?: Bilingue_t
		ressources?: Bilingue_t
	}
	prerequis?: {
		text?: Bilingue_t
		liste?: Array<string>
	}
	mcc?: {
		text?: Bilingue_t
		// dfe?: MccDfe_t
	}
}

export interface CapaciteFlat_t  extends SylBiParapheFlat_t {
	libCapacite: Bilingue_t
	rangCapCom?: number
}

export interface MotClefFlat_t  extends SylBiParapheFlat_t {
	texteMC: Bilingue_t
}

export interface AcquisAppFlat_t  extends SylBiParapheFlat_t {
	libAcquisApp: Bilingue_t
	mcc?: Bilingue_t
}

export interface CompetenceFlat_t  extends SylBiParapheFlat_t {
	libComp: Bilingue_t
	ucModEvalComp: string // "C" | "E"
	ucNiveauComp: string // "1" | "2" | "3" | "4"
	ucBlocs: Array<string>
}

type DetailsElpFlat_t = {
	factuel?: FactuelFlat_t
	modalite?: ModaliteFlat_t
	notice?: NoticeElpFlat_t
	capacites?: Array<CapaciteFlat_t>
	motsClefs?: Array<MotClefFlat_t>
	acquisApp?: Array<AcquisAppFlat_t>
	competences?: Array<CompetenceFlat_t>
}
export interface ElementBaseFlat_t {
	code: string
	lic: string
	nbCrd: number
	listes: Array<ListeFlat_t>
}

export interface ElementFlat_t extends ElementBaseFlat_t {
	apoNat: LicNel_t
	nature: "période" | "module" | "matière"
	details?: DetailsElpFlat_t
	charges?: ChargesElp_t
}

export type ListeFlat_t = {
	codLse: string
	licLse: string
	typLse: "F" | "O" | "X" // F = Facultative O = Obligatoire X = Obligatoire à choix
	nbMinElp: number
	nbMaxElp: number
	nature: "liste-périodes" | "liste-modules" | "liste-matières"
	elements: Array<ElementFlat_t>
}

interface NoticeEtpFlat_t extends SylParapheFlat_t {
	commentaire?: string
	libelle?: Libelle_t
	contexte?: Bilingue_t
	type: "noticeEtp"

}

export interface EtapeFlat_t extends ElementBaseFlat_t {
	annee: number
	apoNat: "Etape"
	nature: "étape"
	notice?: NoticeEtpFlat_t
	charges?: ChargesEtp_t
}

type FormationFlat_t = {
	diplome: DiplomeFlat_t
	parcours: ParcoursFlat_t
	blocsCompetence: MapBlocsComp_t
	niveauxCompetence: MapNiveauxComp_t 
	modalitesEvalCompetence: MapModEvalComp_t
	annees: Array<number>
	etapes: Array<EtapeFlat_t>
}

/**
 * EcoleFlat_t
 * 
 * Objet de l'API issu de
 * backend-sagesse/app/Http/Controllers/ApogeeTopController.php (fonction flat)
 */
type EcoleFlat_t = Array<FormationFlat_t>


/**
 * FormationContextFlat_t
 * 
 * Contexte de formation: description du parcours
 */
export interface FormationContextFlat_t {
	annee?: number
	diplome?: DiplomeFlat_t
	parcours?: ParcoursFlat_t
	langue: "fr" | "en",
	blocsCompetence?: MapBlocsComp_t
	niveauxCompetence?: MapNiveauxComp_t 
	modalitesEvalCompetence?: MapModEvalComp_t
}

interface Props {
	langue: "fr" | "en"
}

export const ContexteFormationFlat = createContext<FormationContextFlat_t>({ langue: "fr" })

export const Imprimable = ({ langue }: Props) : JSX.Element | null => {
	const { apiAccess } = useSanctum()
	const { vet, anneeU } = useParams() // paramètre de la route
	const [formation, setFormation] = useState<FormationFlat_t | null | undefined>(null)

	const fetch = async () : Promise<void> => {
		try {
			const reponse = await apiAccess.get<EcoleFlat_t>(
				"/api/lecture?annee="+anneeU+"&etape="+vet
			)
			setFormation(reponse.data[0])
			console.log(reponse.data)
		}
		catch(error) {
			if (process.env.NODE_ENV == "development")
				console.error( {from: "Apogee.Formation.fetch", error})
		}
	}

	// actif seulement au montage
	useEffect(() => {  fetch() }, []) 

	const frm = formation?.parcours

	const header = (! frm 
		? <header><h1 className="text-center text-muted mb-4">Etape {vet}</h1></header>
		: <header>
			<h1 className="text-center text-muted mb-4">Formation {frm.code} ({frm.nom ?? ""})</h1>
			<h3 className="text-center text-muted mb-4">
				<LigneBilingueFlat texte={ frm.lic } langue={ langue }/>
			</h3>
			{ frm.desc &&
				<div className="ms-2 me-auto">
					<span className="text-primary">Description&thinsp;: </span>
					<LigneBilingueFlat texte={ frm.desc }  langue={ langue }/>
				</div>
			}
		</header>
	)

	const nbEtp = formation?.etapes.length
	const annee = anneeU && Number(anneeU)

	return <>
		{ header }
		{
			formation && nbEtp && annee
			&& <ContexteFormationFlat.Provider value={ {
				annee: annee,
				diplome: formation?.diplome,
				parcours: formation?.parcours,
				langue: langue,
				blocsCompetence: formation?.blocsCompetence,
				niveauxCompetence: formation?.niveauxCompetence ,
				modalitesEvalCompetence: formation?.modalitesEvalCompetence
			} }>
				<Container fluid>
					<h1>Année universitaire {annee} - {annee + 1}</h1>
					{ Object.values(formation?.etapes).map((etp, ixe) => {
						return <ElpFlat element={etp} annee={annee} key={ixe} />
					} ) }
				</Container>
			</ContexteFormationFlat.Provider>
		}
	</>
}
