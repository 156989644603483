import React from "react"
import { useState } from "react"
import { Alert, Card, Col, Form, Modal, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { CplDuree_t, CpldVal_t, CpldEns_t, CpldProj_t, CpldSta_t } from "./syllabus-types"
import { LicNel_t } from "../apogee/apogee-types"
import { useSanctum } from "../sanctum/sanctum"

interface PropsTravail {
	header?: JSX.Element
	cplDuree?: CplDuree_t | null
	nature: LicNel_t
	update?: (value: CplDuree_t) => void
}

// type natType_t = "ens" | "stage" | "projet" | "val"
type natType_t = CplDuree_t["type"]

const isNatType = (s: string): s is natType_t => {
	switch (s) {
	case "ens": case "stage": case "projet": case "val": return true
	default: return false
	}
}

export const Travail = (
	{ header, cplDuree, update, nature } : PropsTravail
): JSX.Element | null => {

	// if (cplDuree?.type !== type && (type !== "stage" || cplDuree?.type !== "val")) cplDuree = null

	const [edit, setEdit] = useState(false)
	const { authState: { user } } = useSanctum()
	const onclickEdit = () => setEdit(true)
	const onclickCancel = () => setEdit(false)

	const Formulaire = () : JSX.Element => {

		interface formState {
			type: natType_t
			val: CpldVal_t
			ens: CpldEns_t
			projet: CpldProj_t
			stage: CpldSta_t
		}

		const natType = () : natType_t => {
			switch (nature) {
			case "Projet" : case "Projet UE": return "projet"
			case "Stage" : return "stage"
			// case "CC ENT" : case "ECUE" : case "UE" : 
			default: return "ens"
			}
		}

		const [ form, setForm ] = useState<formState> ({
			type: cplDuree?.type ?? natType(),
			val: (cplDuree?.type === "val") ? cplDuree : { type: "val", fafp: 1, the: 0 },
			ens: (cplDuree?.type === "ens") ? cplDuree : { type: "ens", the: 0 },
			projet: (cplDuree?.type === "projet") ? cplDuree 
				: { type: "projet", fafp: 1, dureeET: 6, dureeHET: 0 },
			stage: (cplDuree?.type === "stage") ? cplDuree : { type: "stage", min: 0, max: 26 }
		})

		const onSubmit = () => {
			if (update) update(form[form.type])
			setEdit(false)
		}	

		const [ erreurs, setErreurs ] = useState<{[key: string]: boolean}>({})

		const genRex = /^(?:(ens|stage|projet|val)\.(.*))$/
		const stageRex = /^(?:min|max)$/
		const projetRex = /^(?:fafp|dureeET|dureeHET)$/
		const valRex = /^(?:fafp|the)$/
		const ensRex = /^the$/

		const setStage = (field: string, val: number) => {
			if (! stageRex.test(field) || val < 0 ) return
			if ( val <= 26 ) {
				if (field === "min" && val > form.stage.max 
					|| field === "max" && val < form.stage.min) {
					setForm({ ...form, stage: { ...form.stage, min: val, max:val }})
					setErreurs({...erreurs, stage: true})
				} else {
					setForm({...form, stage: { ...form.stage, [field]: val }})
					setErreurs({...erreurs, stage: false})
				}
			} else setErreurs({...erreurs, stage: true})
		}

		const setEns = (field: string, val: number) => {
			if (! ensRex.test(field) || val < 0 ) return
			if ( val <= 300 ) {
				setForm({...form, ens: { ...form.ens, the: val }})
				setErreurs({...erreurs, ens: false})
			} else setErreurs({...erreurs, ens: true})
		}

		const setVal = (field: string, val: number) => {
			if (! valRex.test(field) || val < 0 ) return
			if ( field === "fafp" && (val > 6 || val + form.val.the < 1)
				|| field === "the" && (val > 300 || val + form.val.fafp < 1)) {
				setErreurs({...erreurs, val: true})
			} else {
				setForm({...form, val: { ...form.val, [field]: val }})
				setErreurs({...erreurs, val: false})
			}
		}

		const setProjet = (field: string, val: number) => {
			if (! projetRex.test(field) || val < 0 ) return
			if ( field === "fafp" && (val > 24 || val < form.projet.dureeET + form.projet.dureeHET)
				|| field === "dureeET" && (val > 300 || form.projet.fafp < val + form.projet.dureeHET)
				|| field === "dureeHET" && (val > 300 || form.projet.fafp < val + form.projet.dureeET)) {
				setErreurs({...erreurs, projet: true})
			} else {
				setForm({...form, projet: { ...form.projet, [field]: val }})
				setErreurs({...erreurs, projet: false})
			}
		}

		const setField = (field: string, value: string) => {
			if (field === "type") {
				if ( isNatType(value) ) setForm({ ...form, type: value })
			} else {
				const parse = field.match(genRex)
				if (parse == null || parse[1] !== form.type) return
				switch (form.type) {
				case "ens": setEns(parse[2], +value) ; return
				case "stage": setStage(parse[2], +value) ; return
				case "projet": setProjet(parse[2], +value) ; return
				case "val": setVal(parse[2], +value) ; return
				}
			}
		}

		const types = [
			{ name: "Stage", value: "stage" },
			{ name: "Projet", value: "projet" },
			{ name: "Enseignement", value: "ens" },
			{ name: "validation de compétence", value: "val" },
		]
		
		return <Form>
			<Row className="my-3">
				<Col xs={12} className="text-center">
					<h5> Type d&apos;enseignement </h5>
				</Col>
				<Col xs={12} className="text-center">
					<ToggleButtonGroup name="type-ens"
						type="radio" value={form.type}
						//onChange={console.log}
						onChange={(e) => setField("type", e)}
					>
						{types.map((type, idx) => (
							<ToggleButton key={idx} id={`type-${idx}`}
								value={type.value}
								variant="outline-primary"
							>
								{type.name}
							</ToggleButton>
						))}
					</ToggleButtonGroup>
				</Col>
			</Row>
			<OkCancel  cancel={onclickCancel} valid={onSubmit}/>
			{ form.type === "stage" && <>
				<h4 className="mt-2">Nombre de semaines de stage</h4>
				<Form.Group as={Row}  className="my-3" controlId="stage.min">
					<Form.Label column sm={8} className='text-primary'>
						Nombre minimum de semaines de stage
					</Form.Label>
					<Col sm={4}> <Form.Control type="number" value={form.stage.min}
						onChange={ e => setField("stage.min", e.target.value) }/></Col>
				</Form.Group>
				<Form.Group as={Row} className="my-3"  controlId="stage.max">
					<Form.Label column sm={8} className='text-primary'>
						Nombre maximum de semaines de stage
					</Form.Label>
					<Col sm={4}><Form.Control type="number"  value={form.stage.max}
						onChange={ e => setField("stage.max", e.target.value) }/></Col>
				</Form.Group>
				{erreurs.stage && <Alert variant="danger" className="lh-sm">
					Le nombre max de semaine de stages ne peut pas être inférieur
					au nombre min, et la règlementation interdit les stages de plus
					de six mois (26 semaines).
				</Alert>}
			</> }
			{ form.type === "ens" && <>
				<h4 className="mt-2">
					Temps de travail hors encadrement pour un enseignement traditionnel
				</h4>
				<Form.Group as={Row}  className="my-3" controlId="ens.the">
					<Form.Label column sm={8} className='text-primary'>
						Temps de travail hors encadrement (h)
					</Form.Label>
					<Col sm={4}> <Form.Control type="number" value={form.ens.the}
						onChange={ e => setField("ens.the", e.target.value) }/></Col>
					<Col className="lh-sm"><Form.Text className="text-secondary">
						Une estimation du temps de travail supplémentaire 
						habituellement nécessaire pour acquérir les compétences
						visées par l&apos;enseignement.
					</Form.Text></Col>
				</Form.Group>
				{erreurs.ens && <Alert variant="danger" className="lh-sm">
					Limité à 300h (c&apos;est déjà beaucoup trop)...
				</Alert>}
			</> }
			{ form.type === "val" && <>
				<h4 className="mt-2">
					Temps de travail pour une validation de compétence
				</h4>
				<Form.Group as={Row}  className="my-3" controlId="val.fafp">
					<Form.Label column sm={8} className='text-primary'>
						Temps de face à face présentiel (h)
					</Form.Label>
					<Col sm={4}> <Form.Control type="number" value={form.val.fafp}
						onChange={ e => setField("val.fafp", e.target.value) }/></Col>
					<Col className="lh-sm"><Form.Text className="text-secondary">
						Temps nécessaire à un entretien individuel, une soutenance devant un
						jury, etc. Une valeur minimale est donnée par le nombre d&apos;heures
						&ldquo;référentiel équivalence horaire&rdquo; par élève pour 
						l&apos;activité considérée. 
					</Form.Text></Col>
				</Form.Group>
				<Form.Group as={Row}  className="my-3" controlId="val.the">
					<Form.Label column sm={8} className='text-primary'>
						Temps de travail hors encadrement (h)
					</Form.Label>
					<Col sm={4}> <Form.Control type="number" value={form.val.the}
						onChange={ e => setField("val.the", e.target.value) }/></Col>
					<Col className="lh-sm"><Form.Text className="text-secondary">
						Une estimation du temps de travail personnel supplémentaire 
						habituellement nécessaire pour évaluer/justifier 
						les compétences à valider.
					</Form.Text></Col>
				</Form.Group>
				{erreurs.val && <Alert variant="danger" className="lh-sm">
					Le temps de face à face présentiel est limité à 6h,
					le temps de travail hors encadrement à 300h et la somme des
					deux ne peut pas être inférieure à 1h
				</Alert>}
			</> }
			{ form.type === "projet" && <>
				<h4 className="mt-2">Temps de travail associé à un projet</h4>
				<Form.Group as={Row}  className="my-3" controlId="projet.dureeET">
					<Form.Label column sm={8} className='text-primary'>
						Temps de travail inscrit à l&apos;emploi du temps (h)
					</Form.Label>
					<Col sm={4}> <Form.Control type="number" value={form.projet.dureeET}
						onChange={ e => setField("projet.dureeET", e.target.value) }/></Col>
				</Form.Group>
				<Form.Group as={Row} className="my-3"  controlId="stage.fafp">
					<Form.Label column sm={8} className='text-primary'>
						Temps de face à face présentiel (h)
					</Form.Label>
					<Col sm={4}><Form.Control type="number"  value={form.projet.fafp}
						onChange={ e => setField("projet.fafp", e.target.value) }/></Col>
					<Col className="lh-sm"><Form.Text className="text-secondary">
						Comptées dans le temps de travail inscrit (ou non) à
						l&apos;emploi du temps,
						ces heures correspondent au temps d&apos;encadrement du projet.
						Une valeur minimale est donnée par le nombre d&apos;heures
						&ldquo;référentiel équivalence horaire&rdquo; par élève pour le projet
						considéré.
						Raisonnablement, ce temps ne peut pas être supérieur à la somme du
						temps de travail (inscrit et non inscrit à l&apos;emploi du temps).
					</Form.Text></Col>
				</Form.Group>
				<Form.Group as={Row}  className="my-3" controlId="projet.dureeHET">
					<Form.Label column sm={8} className='text-primary'>
						Temps de travail hors emploi du temps (h)
					</Form.Label>
					<Col sm={4}> <Form.Control type="number" value={form.projet.dureeHET}
						onChange={ e => setField("projet.dureeHET", e.target.value) }/></Col>
					<Col className="lh-sm"><Form.Text className="text-secondary">
						Une estimation du temps de travail supplémentaire 
						habituellement nécessaire pour atteindre les objectifs.
					</Form.Text></Col>
				</Form.Group>
				{erreurs.projet && <Alert variant="danger" className="lh-sm">
					Le temps de travail inscrit à l&apos;emploi du temps et le temps hors
					emploi du temps sont tous les deux limités à 300h. Le temps de face à
					face présentiel est, lui, limité à 24h. Enfin, la somme des
					temps de travail inscrit et non inscrit à l&apos;emploi
					du temps doit être au moins égale à 1h et supérieure au temps de
					face à face présentiel.
				</Alert>}
			</> }
			<OkCancel  cancel={onclickCancel} valid={onSubmit}/>
		</Form>
	}


	const CplDuree = () : JSX.Element | null => {
		switch (cplDuree?.type) {
		case "stage": return <Card.Text className="mb-0">
			Stage de {cplDuree.min} à {cplDuree.max} semaines. 
		</Card.Text>
		case "projet": return <Card.Text className="mb-0">
			Projet {cplDuree.dureeET > 0 ? `de ${cplDuree.dureeET} heure` : "sans heure"}
			{cplDuree.dureeET != 1 ? "s inscrites " : " inscrite "}
			à l&apos;emploi du temps
			{ cplDuree.fafp > 0
				? `, incluant ${cplDuree.fafp} heure` 
					+ ((cplDuree.fafp > 1) ? "s" : "") 
					+ " d'encadrement de projet"
				: ""
			}
			{ cplDuree.dureeHET > 0 
				? `, et nécessitant environ ${cplDuree.dureeHET} heure`
					+ ((cplDuree.dureeHET > 1) ? "s de " : " de ")
				: ", pour lequel il n'est pas habituellement nécessaire de prévoir du "
			} travail 
			{ cplDuree.dureeET > 0
				? " supplémentaire en dehors de ces heures."
				: " hors encadrement." 
			}
		</Card.Text>
		case "ens": return <Card.Text className="mb-0">
			Les activités pédagogiques en face à face présentiel inscrites à l&apos;emploi
			du temps
			{cplDuree.the > 0
				? ` nécessitent environ ${cplDuree.the} heure`
					+ ((cplDuree.the > 1) ? "s " : " ")
				: " ne nécessitent pas "
			} de travail supplémentaire hors encadrement.
		</Card.Text>
		case "val": return <Card.Text className="mb-0">
			Cette activité de validation de compétences 
			{ cplDuree.fafp > 0
				? ` nécessite ${cplDuree.fafp} heure`
					+ ((cplDuree.fafp > 1) ? "s " : " ")
				: " ne nécessite pas "
			}
			d&apos;activités pédagogiques en face à face présentiel 
			(entretien individuel, soutenance devant un jury, etc.) et
			{ cplDuree.the > 0
				? ` requiert habituellement ${cplDuree.the} heure`
					+ ((cplDuree.the > 1) ? "s " : " ")
				: " ne nécessite habituellement pas "
			}
			de travail supplémentaire hors encadrement.
		</Card.Text>
		}
		return null
	}

	// mémoire pour modal: dialogClassName="modal-50w" 

	if ((cplDuree ?? user ?? null) === null) return null

	return <Card className="mx-1 mb-1">
		<Card.Body className='py-2'>
			<Card.Title className="text-primary mb-2">
				<EditButton onClick={onclickEdit}/>
				Temps de travail
			</Card.Title>
			<CplDuree />
		</Card.Body>
		<Modal show={edit} keyboard={false} backdrop="static">
			{ header }
			<Modal.Body>{ edit && <Formulaire /> }</Modal.Body>
		</Modal>
	</Card>
}

