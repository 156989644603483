import React from "react"
import { useContext } from "react"
import { Card } from "react-bootstrap"
import { ContexteFormationFlat } from "./imprimable"
import { Bilingue_t } from "../utilities/bilingue"

interface PropsTexteBilingue {
	surtitre?: JSX.Element
	titre: string
	texte: Bilingue_t
	html?: boolean
}

/**
 * TexteBilingueFlat
 * 
 * texte bilingue français-anglais éditable
 * 
 * @param param0 : PropsTexteBilingue
 * @returns  JSX.Element
 */
export const TexteBilingueFlat = (
	{ titre, texte, html }: PropsTexteBilingue
): JSX.Element => {
	const ctxt = useContext(ContexteFormationFlat)
	const contenu = (ctxt.langue == "en" && texte.en) ? texte.en : texte.fr

	return <Card.Body>
		<h3>{titre}</h3>
		{ (! html) ? <Card.Text>contenu</Card.Text>
			: <div dangerouslySetInnerHTML={ {__html: contenu } } />
		}
	</Card.Body>
}

interface PropsLigneBilingue {
	texte: Bilingue_t | null
	langue: "fr" | "en"
}

/**
 * LigneBilingueFlat
 * 
 * ligne de texte bilingue français-anglais
 * 
 * @param param0 : PropsLigneBilingue
 * @returns  JSX.Element
 */
export const LigneBilingueFlat = ( { texte, langue } : PropsLigneBilingue ): JSX.Element | null => {
	const contenu = (langue == "en" && texte?.en) ? texte.en : texte?.fr
	return (contenu) ? <>
		{contenu}
	</> : null
}
