import React from "react"
import { Card } from "react-bootstrap"
import { ElpFlat } from "./elp-flat"
import { ListeFlat_t } from "./imprimable"

interface PropsListe {
	annee: number
	listes: Array<ListeFlat_t>
}

interface PropsHeader {
	lst: ListeFlat_t
}

const ListHeader = ({ lst }: PropsHeader) => {
	switch (lst.typLse) {
	case "O" : return (
		<Card.Header>
			{lst.codLse}: Liste obligatoire
		</Card.Header>
	)
	case "X" : return (
		<Card.Header>
			{lst.codLse}: Liste obligatoire à choix, sélectionner {
				(lst.nbMinElp == lst.nbMaxElp)
					? "exactement " + lst.nbMinElp + ((lst.nbMinElp < 2) ? " élément " : " éléments ")
					: "de " + lst.nbMinElp + " à " +  lst.nbMaxElp + " éléments "
			} de premier niveau dans la liste.
		</Card.Header>
	)
	}
	return (
		<Card.Header>
			{lst.codLse}: Liste d&apos;éléments facultatifs
		</Card.Header>
	)
}

export const ListesAvecTabsFlat = ({listes, annee}: PropsListe): JSX.Element => (
	<Card.Body>
		{listes.map((lst) => (
			<Card key={lst.codLse}>
				<ListHeader lst={lst} />
				<Card.Body>
					{lst.elements.map((elp) => (
						<ElpFlat annee={annee} element={elp} key={elp.code}/>
					))}
				</Card.Body>
			</Card>
		))}
	</Card.Body>
)

export const ListesSansTabsFlat = ({listes, annee}: PropsListe): JSX.Element => (
	<Card.Body>
		{listes.map((lst) => (
			lst.elements.map((elp) => (
				<ElpFlat annee={annee} element={elp} key={lst.codLse + "-" + elp.code}/>
			))
		))}
	</Card.Body>
)