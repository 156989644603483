import React from "react"
import { useState }  from "react"
import { Card, Form, Modal, Row, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { EditButton, HtmlEditorBilingue, OkCancel } from "./form-utilities"
import { useSanctum } from "../sanctum/sanctum"

export interface Bilingue_t {
	fr: string
	en?: string
}

interface PropsTexteBilingue {
	header?: JSX.Element
	surtitre?: JSX.Element
	titre: string
	texte: Bilingue_t
	html?: boolean
	tip: string
	update?: (value: Bilingue_t) => void
}

/**
 * TexteBilingue
 * 
 * texte bilingue français-anglais éditable
 * 
 * @param param0 : PropsTexteBilingue
 * @returns  JSX.Element
 */
export const TexteBilingue = (
	{ titre, texte, html, tip, header, surtitre, update }: PropsTexteBilingue
): JSX.Element | null => {

	const [edit, setEdit] = useState(false)
	const { authState: { user } } = useSanctum()

	// S'il n'y a pas de contenu et s'il n'y a pas d'utilisateur connecté, on n'affiche rien
	if (((texte.fr ?? "").localeCompare("") == 0) && ((texte.en ?? user ?? null) === null)) return null 
	//console.log({texte, user})

	const onclickEdit = () => {
		setEdit(true)
	}

	const Formulaire = () => {
		const { control, handleSubmit } = useForm<Bilingue_t>({
			mode: "onChange",
			defaultValues: {
				fr: texte.fr,
				en: texte.en ?? ""
			}
		})

		const onSubmit = (value: Bilingue_t) => {
			setEdit(false)
			if (update) update(value)
		}

		const onclickCancel = () => setEdit(false)

		return <Form onSubmit={handleSubmit(onSubmit)}>
			<OkCancel cancel={onclickCancel} />
			<Card className="px-3 pt-3 pb-0 mb-3 mt-2">
				{ surtitre }
				<Form.Group controlId={"form_bilingue"} className="lh-sm">
					<Form.Label className='text-primary mb-0 h6'>{titre}</Form.Label><br/>
					<Form.Text className="text-secondary font-italic">{tip}</Form.Text><br/>
					<Form.Text className="text-secondary font-italic">
						Version française
					</Form.Text>
					<HtmlEditorBilingue name={"fr"} control={control}/>
					<Form.Text className="text-secondary font-italic">
						Version anglaise (si elle n&apos;est pas définie, elle pourra l&apos;être
						par traduction automatique de la version française après la saisie
						du syllabus en français).
					</Form.Text>
					<HtmlEditorBilingue name={"en"} control={control}/>
				</Form.Group>
			</Card>
			<OkCancel cancel={onclickCancel} />
		</Form>
	}

	return <Col xs="12">
		<Card className="mx-1 mb-1">
			<Card.Header className="text-primary h5">
				<EditButton onClick={onclickEdit} />
				{titre}
			</Card.Header>
			<Card.Body className="pt-3 pb-0 texteSyllabus">
				{ (! html) ? <Card.Text>texte.fr</Card.Text>
					: <div dangerouslySetInnerHTML={ {__html: texte.fr } } />
				}
			</Card.Body>
			{ texte?.en &&
				<Row className="no-gutters align-items-center">
					<Col xs={1} className="pt-1">
						<p className="english-mark text-center">en&thinsp;:</p>
					</Col>
					<Col xs={11}>
						<Card className="pt-2 px-2 mb-2 texteSyllabus" style={ { borderColor: "purple" } }>
							{ (! html)
								? <Card.Text className="mb-0">texte.en</Card.Text>
								: <div className="mb-0" dangerouslySetInnerHTML={ {__html: texte.en } } />
							}
						</Card>
					</Col>
				</Row>
			}
			<Modal dialogClassName="modal-90w" show={edit} keyboard={false} backdrop="static">
				{ header }
				<Modal.Body>
					{ edit && <Formulaire /> }
				</Modal.Body>
			</Modal>
		</Card>
	</Col>
}

interface PropsLigneBilingue {
	texte?: Bilingue_t | null
}

/**
 * LigneBilingue
 * 
 * ligne de texte bilingue français-anglais
 * 
 * @param param0 : PropsLigneBilingue
 * @returns  JSX.Element
 */
export const LigneBilingue = ( { texte } : PropsLigneBilingue ): JSX.Element | null => {
	return (texte) ? <>
		{texte.fr}
		{texte.en ? <>&ensp;(<sup  className="english-mark">en </sup> {texte.en})</> : null}
	</> : null
}
