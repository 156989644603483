import React from "react"
import {
	Reducer, useEffect,
	useReducer, useState,
	useContext
} from "react"
import Table from "react-bootstrap/Table"
import {
	Badge,
	Button, Card, Col, Dropdown, Form,
	Modal, OverlayTrigger, Popover, Row, Tooltip
} from "react-bootstrap"
import { nanoid } from "nanoid"
import {
	CompetenceH_t, Competence_t,
	NiveauComp_t, ModEvalComp_t,
	DescElpRead_t, DescElpWrite_t, MapNiveauxComp_t, MapModEvalComp_t
} from "./syllabus-types"
import { Bilingue_t } from "../utilities/bilingue"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { ErrorToast } from "../utilities/error-toast"
import { ContexteDetails } from "./details"
import { ContexteFormation } from "../apogee/formation"
import { useSanctum } from "../sanctum/sanctum"
import CardHeader from "react-bootstrap/esm/CardHeader"


interface Bloc_t {
	// Le bloc
	idBloc: number
	numFrComp: string
	appellation: Bilingue_t
	updated_at: Date			// Paraphe du Bloc
	updated_by?: string			// Paraphe du Bloc
	// le lien au parcours
	validite: string			// validité du lien parcours-bloc
	numeroBloc?: number	// numéro du bloc dans le parcours
	typeBloc?: string	// type du bloc dans le parcours
}

interface CompetenceF_t { // F comme Formulaire
	// la compétence
	idCompetence: number
	libComp: Bilingue_t
	lstBlocs: Array<string>		// les blocs du parcours contenant la compétence
	// lien à l'Elp (troncCodeElp)
	troncCodElp?: string
	ucModEvalComp?: string		// propre: "C" | "E" peut changer...
	ucNiveauComp?: string		// propre: "0" | "1" | "2" | "3"> peut changer...
	ucModEvalCompH?: Array<string>		// Hérité Array<"C" | "E"> peut changer...
	ucNiveauCompH?: string		// Hérité Array<"0" | "1" | "2" | "3"> peut changer...
	caractere?: "O" | "X" | "F"
	updated_at?: Date		// n'existe que quand la compétence est propre à troncCodElp
	updated_by?: string // n'existe éventuellement que quand la compétence est propre à troncCodElp
	validite?: string		// n'existe que quand la compétence est propre à troncCodElp
	brancheCodElp?: Array<string>
	codElp?: Array<string>	// les descendants de troncCodElp qui lui transmettent la compétence
}

interface DescCompRead_t {
	blocs: {
		[numFrComp: string] : Bloc_t
	}
	competences: Array<CompetenceF_t>
}

interface DescCompWrite_t {
	parcours?: string	// codParcours
	blocs?: "A"
	competences?: "A"
}

/**
 * competences
 * 
 * Objet React de manipulation (affichage/édition) des compétences du RNCP
 * associées à un élément
 * 
 * @returns JSX.Element | null 
 */
export const Competences = (): JSX.Element | null => {
	const { apiAccess, authState: { user } } = useSanctum()

	const [comps, setComps] = useState<Array<CompetenceH_t> | null>(null)
	const [compsf, setCompsf] = useState<Array<CompetenceF_t> | null>(null) // initialisation formulaire

	const [nvcps, setNvcps] = useState<MapNiveauxComp_t | null>(null)
	const [mecps, setMecps] = useState<MapModEvalComp_t | null>(null)
	const [blocs, setBlocs] = useState<{[numFrComp: string] : Bloc_t} | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)
	const [edit, setEdit] = useState(false)
	const ctxtDet = useContext(ContexteDetails)
	const ctxtFrm = useContext(ContexteFormation)
	const urlRNCP = `/api/rncp/${ctxtDet.code}/${ctxtDet.annee}`

	const onclickEdit = () => {
		fetchCompetences()
		setEdit(true)
	}

	/**
	 * fetchDescription
	 * 
	 * fonction asynchrone d'écriture/lecture d'une liste de compétences
	 * 
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 * 
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = async (valeur: DescElpWrite_t) : Promise<void> => {
		try {
			const reponse = await apiAccess.post<DescElpRead_t>(ctxtDet.url, valeur)
			if (reponse.data.competences !== undefined) {
				setComps(reponse.data.competences)
				// console.log({competences: reponse.data.competences})
			}
			if (reponse.data.niveauxcomp !== undefined) setNvcps(reponse.data.niveauxcomp)
			if (reponse.data.modsevalcomp !== undefined) setMecps(reponse.data.modsevalcomp)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}

	/**
	 * fetchBlocs
	 * 
	 * fonction asynchrone de lecture d'une liste de blocs
	 * 
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 * 
	 */
	const fetchBlocs = async () : Promise<void> => {
		const charge: DescCompWrite_t = { parcours: ctxtFrm.formation?.codParcours, blocs: "A" }
		try {
			const reponse = await apiAccess.post<DescCompRead_t>( urlRNCP, charge )
			if (reponse.data.blocs !== undefined) setBlocs(reponse.data.blocs)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}
	
	/**
	 * fetchCompetences
	 * 
	 * fonction asynchrone d'écriture/lecture d'une liste de compétences
	 * 
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 * 
	 * @param valeur : DescElpWrite_t
	 */
	const fetchCompetences = async () : Promise<void> => {
		const charge: DescCompWrite_t = { parcours: ctxtFrm.formation?.codParcours, competences: "A" }
		try {
			const reponse = await apiAccess.post<DescCompRead_t>( urlRNCP, charge )
			if (reponse.data.competences !== undefined) setCompsf(reponse.data.competences)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}
	
	/**
	 * Appel useEffect:
	 * 
	 * Les niveaux de compétence, leurs modalités d'évaluation et la liste
	 * des blocs ne changent pas au cours d'une session. Les compétences,
	 * elles, doivent être rafraichies (mais c'est fait par les fetchDescription
	 * déclenchés par le formulaire).
	 * 
	 * Ce useEffect est configuré comme un "componentDidMount", mais le code
	 * est écrit pour fonctionner sans overhead significatif
	 * en "componentDidUpdate"
	 * 
	 */
	useEffect(() => { 
		fetchDescription( {
			parcours: ctxtFrm.formation?.codParcours ?? undefined,
			competences: null,
			niveauxcomp: (nvcps === null) ? null : undefined,
			modsevalcomp: (mecps === null) ? null : undefined
		} )
		fetchBlocs()
	},[] )

	interface PropsListeBlocs {
		lstBlocs: Array<string> | null
	}

	const ListeBlocs = ( { lstBlocs }: PropsListeBlocs ) : JSX.Element => {
		const id = nanoid()
		return <span className="text-info">
			{lstBlocs && lstBlocs.length > 0
				? (lstBlocs.length > 1 ? "Blocs " : "Bloc ")
				: ("Compétence relative à un autre parcours que " + ctxtFrm.formation?.codParcours)
			}
			{lstBlocs && lstBlocs.map((nfc, ix, tab) =>
				<OverlayTrigger placement={"top"} key={nfc} overlay={
					<Popover id={`Popover-${id}-${nfc}`} className="text-bg-info">
						<Popover.Header as="h3" className="text-bg-warning">Bloc {nfc}</Popover.Header>
						<Popover.Body as="h6">{blocs && blocs[nfc].appellation.fr }</Popover.Body>
					</Popover>
				}>
					<span>{(ix+1 < tab.length) ? nfc + ", " : nfc}</span>
				</OverlayTrigger>
			)}
		</span>
	}

	const TemoinModif = (  ) : JSX.Element =>
		<>
			&nbsp;
			<Badge bg="danger" pill
				className="position-absolute top-0 start-100 translate-middle border border-light"
			>
				&nbsp;
			</Badge>
		</>
	/**
	 * Formulaire
	 * 
	 * Formulaire de modification de la liste des compétences
	 * 
	 * @returns JSX.Element
	 */
	const Formulaire = () : JSX.Element => {
		type CC_t = { // représentation des compétences dans le formulaire
			id: string
			//del: boolean
			propre: boolean
			propagé: boolean
			verrouillé: boolean
			donateurs: string | null
			org: CompetenceF_t
			modev: string | null
			niveau: string | null
			val: string | null
		}

		type action_t = { // actions du reducer
			type: string
			value?: Partial<CC_t>
		}

		// useEffect(() => console.log({compf}))

		/**
		 * ccReducer
		 * 
		 * Interprête la commande passée dans action et renvoie une nouvelle version
		 * de state.
		 * 
		 * @param state : Array<CC_t>
		 * @param action : action_t
		 * @returns Array<CC_t>
		 */
		const ccReducer = (state: Array<CC_t>, action: action_t): Array<CC_t> => {
			// console.log( { action })
			const ident = action.value?.id
			
			/**
			 * comp2ccf
			 * 
			 * Produit une entrée de formulaire (CC_t) à partir d'une compétence
			 * (partielle) telles que celles qui sont renvoyées par l'API.
			 * 
			 * @param cc : CompetenceH_tp (compétence "hiérarchique" partielle)
			 * @returns CC_t (entrée de formulaire)
			 */
			function comp2ccf(cc: CompetenceF_t): CC_t {
				const propre = (cc.troncCodElp !== undefined 
					&& cc.codElp?.includes(cc.troncCodElp)) ?? false
				const propagé = cc.brancheCodElp !== undefined
				return {
					id: nanoid(),
					//del: false,
					propre: propre,
					propagé: propagé,
					verrouillé: cc.lstBlocs.length == 0,
					donateurs: cc.codElp?.filter(s => s !== cc.troncCodElp).join(", ") ?? null,
					org: cc,
					modev: cc.ucModEvalComp ?? null,
					niveau: cc.ucNiveauComp ?? null,
					val: cc.validite ?? null
				}
			}

			switch (action.type) {
			//case "reset": return []
			case "init": // réinitialisation avec les acquis d'app.
				// l'ordre initial est celui du tableau des comps qui est censé être ordonné
				return compsf?.map<CC_t>( comp2ccf ) ?? []
			case "bascule": {
				if (! ident) return state // id non indiqué...
				else return state.map( ccf => {
					if ( ccf.id === ident ) {
						if (! ccf.propre) return { ...ccf,
							propre: true,
							niveau: ( ccf.niveau !== null
								? ccf.niveau
								: ( ccf.org.ucNiveauComp
									?? ccf.org.ucNiveauCompH
									?? (nvcps ? Object.values(nvcps)[0].ucNiveauComp : "0")
								)
							),
							modev: ( ccf.modev !== null
								? ccf.modev
								: ( ccf.org.ucModEvalComp
									?? (ccf.org.ucModEvalCompH && ccf.org.ucModEvalCompH[0])
									?? (mecps ? Object.values(mecps)[0].ucModEvalComp : "C")
								)
							),
							val: (ccf.val !== null ? ccf.val : `[${ctxtDet.annee},)`)
						}
						else return { ...ccf, propre: false }
					} else return ccf
				})
			}
			case "maj": { // le ccf est désigné par son id
				if (! ident) return state // id non indiqué...
				return state.map(  ccf => {
					if ( ccf.id !== ident ) return ccf
					else {
						return { // Les champs non mis à jour sont nuls ou undefined (dans action.value)
							id: ccf.id,
							propre: action.value?.propre ?? ccf.propre,
							propagé: ccf.propagé,
							verrouillé: ccf.verrouillé,
							donateurs: ccf.donateurs,
							org: ccf.org,
							modev: action.value?.modev ?? ccf.modev,
							niveau: action.value?.niveau ?? ccf.niveau,
							val: action.value?.val ?? ccf.val
						}
					}
				})
			}
			default: return state
			}
		}

		const [compf, setCompf] = useReducer<Reducer<CC_t[], action_t>>(ccReducer, [])
		const actionReset: action_t = { type: "reset" }

		if (compf.length == 0 && compsf !== null && compsf.length != 0) setCompf({ type: "init" })

		const onclickCancel = () => {
			setEdit(false)
			setCompf(actionReset)
		}
		const onclickValid = () => {
			const cc: Partial<Competence_t>[] = compf.filter(
				ccf => ccf.propre
			).map<Partial<Competence_t>>(
				(ccf) => ( 
					ccf.modev !== ccf.org.ucModEvalComp
						|| ccf.niveau !== ccf.org.ucNiveauComp
				) ? { // tout est transféré tel qu'affiché, sauf la validité qui sera recalculée en backend
						// idCompetence: ccf.org?.idCompetence ?? -1,
						libComp: {
							fr: ccf.org.libComp.fr
						},
						ucModEvalComp: ccf.modev ?? "C",
						ucNiveauComp: ccf.niveau ?? "0"
					}
					: {
						libComp: {
							fr: ccf.org.libComp.fr
						}
					}
			)
			fetchDescription( { parcours: ctxtFrm.formation?.codParcours, competences: cc } )
			setEdit(false)
			setCompf(actionReset)
		}

		const Prologue = () => <div className="lh-sm">
			<p>
				Les compétences du RNCP sont les compétences terminales visées par la formation.
				Les enseignements participent nécessairement, à des degrés divers,
				à la construction et à l&apos;évaluation de ces compétences.
			</p>
			<p>
				Le présent formulaire permet d&apos;indiquer les compétences auxquelles l&apos;enseignement
				considéré participe. Cette participation est quantifiée à travers deux indicateurs:
				d&apos;une part le mode d&apos;évaluation de la compétence (classique, ou par compétence)
				et, d&apos;autre part, le niveau visé sur une échelle de quatre niveaux ; échelle explicitée
				dans le menu déroulant permettant de choisir le niveau.
				Le formulaire ci-après recense toutes les compétences énumérées dans la fiche
				RNCP de la formation. Au dessus de chaque compétence est énumérée la liste des
				blocs qui la contiennent. En passant le curseur sur l&apos;identifiant du bloc,
				on fait apparaitre une bulle qui en précise le titre.
			</p>
			<p>
				Au dessous de chaque compétence apparaissent trois boutons. Leur couleur, tout
				d&apos;abord, indique si l&apos;élément considéré participe (boutons verts ou jaunes) ou
				non (boutons gris) à la compétence. Lorsque les boutons sont jaunes, l&apos;élément
				participe à la compétence par ses éléments constitutifs. Il n&apos;est, alors, pas possible
				de modifier le niveau ou le mode d&apos;évaluation qui sont hérités des éléments constitutifs.
				Lorsque les boutons sont verts, la compétence est dite propre à l&apos;élément. Le bouton du
				milieu et le bouton de droite permettent alors de chosir, respectivement, le niveau visé et
				le mode d&apos;évaluation. Enfin, le bouton de gauche contrôle l&apos;affectation de la compétence
				à l&apos;élément.
			</p>
			<p>
				Initialement, le formulaire est rempli avec l&apos;état du syllabus. Vu le nombre d&apos;items
				dans la liste, il est difficile de se souvenir des changements faits. Aussi, les 
				boutons qui ne sont pas dans leur état initial sont marqués d&apos;une pastille rouge...
				Dernier détail : les modifications ne deviennent définitives qu&apos;après validation.
			</p>
		</div>

		interface PropsFormAA {
			ccf: CC_t
			readonly?: boolean
		}

		const LibCompetence = ( { ccf }: PropsFormAA ) => (
			<Col>
				<p className="my-0">
					<ListeBlocs lstBlocs={ccf.org.lstBlocs} /><br/>
					<OverlayTrigger placement={"top"} overlay={
						<Tooltip id={`tooltip-${ccf.id}`}>
							{ccf.val ? "Défini pour " + ccf.val : "Non sélectionné"}
						</Tooltip>
					} >
						<span>{ccf.org.libComp.fr}</span>
					</OverlayTrigger>
				</p>
			</Col>
		)

		const nomNiveau = (niv : string | null | undefined) => "Niveau " + (niv ?? "0") + (nvcps !== null ? ": " + nvcps[niv ?? "0"].nomNiveauComp.fr : "")
		const nomMec = (mec : string | null | undefined) => "Mode d'évaluation " + (mec ?? "C") + (mecps !== null ? ": " + mecps[mec ?? "C"].nomModEvalComp.fr : "")

		return <>
			<h1 className="mt-2">Lien aux compétences du RNCP</h1>
			<Prologue/>
			<OkCancel cancel={onclickCancel} valid={onclickValid}/>
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<Form>
				{compf && compf.map( (ccf) => (
					<Row key={`ccf${ccf.id}`} className="my-2 p-1 border border-primary rounded">
						<Col>
							<Row> <LibCompetence ccf={ccf}/> </Row>
							<Row className="mx-0 mt-1">
								<Col>
									<Button size="sm" className="position-relative"
										type="button" disabled={ccf.verrouillé}
										variant={ ccf.propre ? "success" : (ccf.propagé ? "warning" : "secondary")}
										onClick={() => setCompf({type: "bascule", value: {id: ccf.id}})}
									>
										{ ccf.propagé 
											? (( ccf.propre ? `Propre à l'élément ${ctxtDet.code} et propagé de ` : "Seulement propagé de ") + ccf.donateurs)
											: ( ccf.propre ?  `Propre à l'élément ${ctxtDet.code}` : "Non sélectionné")
										}
										{ (ccf.propre != (ccf.org.validite !== undefined)) && <TemoinModif /> }
									</Button>
								</Col>
								<Col>
									{  (ccf.propre && ! ccf.verrouillé)
										? <Dropdown>
											<Dropdown.Toggle id={`niv-${ccf.id}`}size="sm" variant="success" className="position-relative">
												{ nomNiveau(ccf.niveau) }
												{ (ccf.niveau != (ccf.org.ucNiveauComp)) && <TemoinModif /> }
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Header><h2>Niveau visé</h2></Dropdown.Header>
												{ nvcps && Object.values(nvcps).map( ( niv: NiveauComp_t ) =>
													<Dropdown.Item
														key={`ddn-${ccf.id}-${niv.ucNiveauComp}`}
														onClick={ () => setCompf( { type: "maj", value: { id: ccf.id, niveau: niv.ucNiveauComp } } ) }
													>
														<Card>
															<CardHeader> {niv.ucNiveauComp}: {niv.nomNiveauComp.fr} </CardHeader>
															<p className="px-2 py-0 my-0">{niv.descNiveauComp.fr}</p>
														</Card>
													</Dropdown.Item>
												) }
											</Dropdown.Menu>
										</Dropdown>
										: <Button disabled size="sm"
											type="button" variant={ ccf.propre ? "success" : (ccf.propagé ? "warning" : "secondary")}>
											{ccf.propre ? nomNiveau(ccf.niveau) : (ccf.propagé ? nomNiveau(ccf.org.ucNiveauCompH) : "Niveau") }
										</Button>
									}
								</Col>
								<Col>
									{  (ccf.propre && ! ccf.verrouillé)
										? <Dropdown>
											<Dropdown.Toggle id={`mec-${ccf.id}`}size="sm" variant="success"  className="position-relative">
												{ nomMec(ccf.modev) }
												{ (ccf.modev != (ccf.org.ucModEvalComp)) && <TemoinModif /> }
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Header><h2>Mode d&apos;évaluation</h2></Dropdown.Header>
												{ mecps && Object.values(mecps).map( ( mec: ModEvalComp_t ) =>
													<Dropdown.Item 
														key={`ddm-${ccf.id}-${mec.ucModEvalComp}`}
														onClick={ () => setCompf( { type: "maj", value: { id: ccf.id, modev: mec.ucModEvalComp } } ) }
													>
														<Card>
															<CardHeader> {mec.ucModEvalComp}: {mec.nomModEvalComp.fr} </CardHeader>
															<p className="px-2 py-0 my-0">{mec.descModEvalComp.fr}</p>
														</Card>
													</Dropdown.Item>
												) }
											</Dropdown.Menu>
										</Dropdown>
										: <Button disabled size="sm"
											type="button" variant={ ccf.propre ? "success" : (ccf.propagé ? "warning" : "secondary")} >
											Mode d&apos;évaluation&nbsp;
											{ ccf.propre
												? (ccf.modev ?? "C") + (mecps !== null ? ": " + mecps[ccf.modev ?? "C"].nomModEvalComp.fr : "")
												: (ccf.propagé && ccf.org.ucModEvalCompH && mecps !== null
													&& ccf.org.ucModEvalCompH.map( (v : string) => v + " (" + mecps[v].nomModEvalComp.fr +")" ).join(" et "))
											}
										</Button>
									}
								</Col>
							</Row>
						</Col>
					</Row>
				))}
			</Form>

			<OkCancel cancel={onclickCancel} valid={onclickValid}/>
		</>
	}

	const aLePar = (cc : CompetenceH_t) => {
		const date = new Date(cc.updated_at.pivot+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${cc.updated_by.pivot}`
	}

	const lstRows = comps?.map((cc,index) => {
		const famille = cc.brancheCodElp ?? cc.codElp
		const lstBlocs = cc.ucBlocs
		return <tr key={cc.idCompetence}>
			<th scope="row" className="text-center py-2">{index+1}</th>
			<td className="list-group p-1">
				<ListeBlocs lstBlocs={lstBlocs} />
				<p className="my-0">{cc.libComp.fr}</p>
				<p className="text-info small my-0">
					{cc.validite !== null
						? cc.brancheCodElp === null
							? (cc.updated_by.pivot) ? "Affectée " + aLePar(cc) : ""
							: ("Propre à l'élément " + cc.troncCodElp
								+ ((cc.updated_by.pivot) ? ` (affectée ${aLePar(cc)})` : "")
								+ " et propagée de ")
						: cc.brancheCodElp === null ? "" : "Propagée de "
					}
					{cc.brancheCodElp && famille.join(", ")}
				</p>
			</td>
			<td>
				{ nvcps && <> {nvcps[cc.ucNiveauComp]?.nomNiveauComp.fr} ({cc.ucNiveauComp}) ; </> }
				{ mecps && <> {mecps[cc.ucModEvalComp]?.nomModEvalComp.fr} </> }
			</td>
		</tr>
	})

	if (((lstRows?.length ?? 0) === 0 && (user ?? null) === null)) return null

	const widthCol = (val: number) => ({width: val + "%"})

	return <Row><Col xs="12">
		<ErrorToast erreur={erreur} onDismiss={setErreur} />
		<Card className="mx-1 mb-1">
			<Card.Header className="text-primary h5">
				<EditButton onClick={onclickEdit}/>Compétences du RNCP
			</Card.Header>
			<Card.Body className="py-2">
				<Table hover size="sm"  className="my-0">
					<colgroup>
						<col style={widthCol(5)}/>
						<col style={widthCol(75)}/>
						<col style={widthCol(20)}/>
					</colgroup>
					<thead className="thead-light">
						<tr>
							<th scope="col" className="text-center">#</th>
							<th scope="col" className="text-left">Libellé</th>
							<th scope="col" className="text-center">Niveau visé</th>
						</tr>
					</thead>
					<tbody>{lstRows}</tbody>
				</Table>
			</Card.Body>
			<Modal dialogClassName="modal-90w" show={edit} keyboard={false} backdrop="static">
				{ ctxtDet.header }
				<Modal.Body>
					{ edit && <Formulaire /> }
				</Modal.Body>
			</Modal>
		</Card>
	</Col></Row>
}
