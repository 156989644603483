import React from "react"
import { TexteBilingueFlat } from "../bilingue-flat"
import { NoticeElpFlat_t } from "../imprimable"

interface Props {
	notice?:  NoticeElpFlat_t
}

/**
 * NoticeFlat
 * 
 * Objet React de manipulation (affichage/édition) de la notice descriptive d'un élément
 * 
 * @returns JSX.Element | null 
 */
export const NoticeFlat = ( { notice }: Props): JSX.Element | null => {

	if (! notice) return null

	// Liste d'éléments pour prérequis mise en attente
	// (demandera sûrement de modifier TexteBilingue...)
	// De même pour les mcc_dfe
	// mis en attente
	return <>
		{notice.description?.contexte && <TexteBilingueFlat html titre="Contexte et objectifs"
			texte={notice?.description?.contexte}
		/>}
		{notice.description?.contenu && <TexteBilingueFlat html titre="Contenu"
			texte={notice?.description?.contenu}
		/>}
		{notice.description?.ressources && <TexteBilingueFlat html titre="Ressources"
			texte={notice?.description?.ressources}
		/>}
		{notice.prerequis?.text && <TexteBilingueFlat html titre="Prérequis"
			texte={notice?.prerequis?.text}
		/>}
		{notice.mcc?.text && <TexteBilingueFlat html titre="Modalités de contrôle des connaissances"
			texte={notice?.mcc?.text}
		/>}
	</>
}
