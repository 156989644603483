import React from "react"
import { useState, useEffect, createContext } from "react"
import { Card, Modal, Col} from "react-bootstrap"
import { ApogeeElp_t, Charges_t, NatureElp_t } from "../apogee/apogee-types"
import { useSanctum } from "../sanctum/sanctum"
import { ErrorToast } from "../utilities/error-toast"
import { AcquisApprentissage } from "./acquis-apprentissage"
import { Capacites } from "./capacites"
import { Charges } from "./charges"
import { Competences } from "./competences"
import { Factuel } from "./factuel"
import { MotsClefs } from "./mots-clefs"
import { Notice } from "./notice"
import { DescElpRead_t } from "./syllabus-types"

export interface DetailsContext_t {
	url: string
	annee: number
	code: string
	header: JSX.Element
}

interface Props {
	annee: number
	nature: NatureElp_t
	code: string
	ouvert: boolean
	terminal: boolean
}

export const ContexteDetails = createContext<DetailsContext_t>({url: "", annee: 2020, code: "", header: <div />})

export const Details = ({annee, nature, code, ouvert}: Props): JSX.Element | null => {
	const { apiAccess } = useSanctum()

	const [apogee, setApogee] = useState<ApogeeElp_t | null>(null)
	const [charges, setCharges] = useState<Charges_t | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)
	const url = `/api/details/${nature.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/${code}/${annee}`

	const fetchDescription = async () : Promise<void> => {
		try {
			const reponse = await apiAccess.post<DescElpRead_t>(url, { apogee: null, charges: null })
			if (reponse.data.apogee !== undefined) setApogee(reponse.data.apogee)
			if (reponse.data.charges !== undefined) setCharges(reponse.data.charges)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}

	useEffect(() => {
		if (ouvert && (apogee === null)) { fetchDescription() }
	}, [ouvert] )

	if (apogee == null || ! ouvert) return null

	const ModalHeader = <Modal.Header><Modal.Title className='text-primary'>
		Modification syllabus année {annee} - {annee + 1}<br />
			description {nature} {apogee.cod_elp}: {apogee.lic_elp}
	</Modal.Title></Modal.Header>

	const ctxtObj = {url, annee, code, header: ModalHeader }

	return <Col>
		<ErrorToast erreur={erreur} onDismiss={setErreur} />
		<ContexteDetails.Provider value={ctxtObj}>
			<Card >
				{charges && <Charges charges={charges} />}
				<Factuel  />
				<Notice  />
				<Capacites />
				<MotsClefs />
				<AcquisApprentissage />
				<Competences />
			</Card>
		</ContexteDetails.Provider>
	</Col>
}
