import React from "react"
import { useState, useEffect } from "react"
import { Button, Card, Col, Collapse, Row } from "react-bootstrap"
import { nanoid } from "nanoid"
import { Nature_t, Liste_t } from "./apogee-types"
import { ErrorToast } from "../utilities/error-toast"
import { DetailsEtp } from "../syllabus/details-etp"
import { Details } from "../syllabus/details"
import { ListesAvecTabs, ListesSansTabs } from "./listes"
import { useSanctum } from "../sanctum/sanctum"
//import { Edit } from "react-feather"

/**
 * ElementFull
 * 
 * Objet de l'API issu de
 * backend-sagesse/app/Http/Controllers/ApogeeBotController.php (fonction element)
 */
interface ElementFull {
	code: string
	lic: string
	apoNat: string
	nbCrd: number
	nature: Nature_t
	warning : string | null
}

/**
 * Structure
 * 
 * Objet de l'API issu de
 * backend-sagesse/app/Http/Controllers/ApogeeBotController.php (fonction element)
 */
interface Structure {
	element: ElementFull
	listes: Liste_t[]
}

interface Props {
	annee: number
	code: string
	nature: Nature_t
}

export const Elp = ({annee, code, nature}: Props): JSX.Element | null => {
	const { apiAccess } = useSanctum()

	const [ouvert, setOuvert] = useState<boolean>(false)
	const [data, setData] = useState<Structure | null>(null)
	const [uniqId, setUniqId] = useState<string | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)

	const fetch = async () => {
		const natureSA = nature.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
		const url = `/api/structure/${natureSA}/${code}/${annee}`	
		try {
			const reponse = await apiAccess.get<Structure>(url)
			// console.log( { reponse })
			setData(reponse.data)
			setUniqId(nanoid())
		}
		catch(error) {
			if (process.env.NODE_ENV == "development")
				console.error( {from: "Apogee.Elp.fetch", error})
		}
	}

	// s'exécute au montage
	useEffect(() => { fetch() }, []) 

	if (data == null) return null

	let border = null
	switch (nature) {
	case "période":
	case "periode":
		border = "primary" ; break
	case "module" : border = "danger" ; break
	case "matière":
		border = "success" ; break
	default : border = "dark"
	}
	const typeListes: string = data?.listes?.reduce((t: string,l: { typLse: string }) => {
		if (t == "X" || l.typLse == "X") return "X"
		if (t == "F" || l.typLse == "F") return "F"
		return t
	},"O")

	const bouton  = (
		<Button
			variant={ouvert ? "outline-danger" : "outline-info" }
			size="sm" className="col-auto"
			onClick={() => setOuvert(!ouvert)}
			aria-expanded={ouvert}
			aria-controls={"detailsElement-"+uniqId}
		>
			{ouvert ? "Fermer" : "Détails" }
		</Button>
	)

	const etiquette = (
		<Col xs="auto">
			{data.element.nature} ({data.element.apoNat}) {
				data.element.code}: {data.element.lic} ; {
				(data.element.nature) == "matière"
					? "coefficient "+ data.element.nbCrd
					: data.element.nbCrd+" crédits."}
		</Col>
	)

	const details = (
		<Collapse in={ouvert}>
			<div id={"detailsElement-"+uniqId}>
				<Row className="mt-2">
					{ nature == "étape" || nature == "etape"
						? <DetailsEtp annee={annee}
							code={code} ouvert={ouvert}/>
						: <Details annee={annee}
							terminal={data.listes.length == 0}
							nature={nature} code={code} ouvert={ouvert}
						/>
					}
				</Row>
				<Row className="mt-2">{bouton} {etiquette} {/*edit*/} </Row>
			</div>
		</Collapse>
	)

	return (
		<Card border={border} className="border-5">
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<Card.Header className="container-fluid">
				<Row> {bouton} {etiquette} {/*ouvert && edit*/} </Row>
				{data.listes.length > 0 && details}
			</Card.Header>

			{data.listes.length == 0 ? <Card.Body> {details} </Card.Body> :
				(data.listes.length == 1 && typeListes == "O")
					? <ListesSansTabs listes={data.listes} annee={annee} />
					: <ListesAvecTabs listes={data.listes} annee={annee} />
			}
		</Card>
	)
}
