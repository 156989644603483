import React from "react"
import { useContext } from "react"
import { Card } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { ContexteFormationFlat, MotClefFlat_t } from "../imprimable"

interface Props {
	motsclefs: Array<MotClefFlat_t>
}

/**
 * MotsClefsFlat
 * 
 * Objet React de manipulation (affichage/édition) des mots clefs associés à un élément
 * 
 * @returns JSX.Element | null 
 */
export const MotsClefsFlat = ( { motsclefs }: Props): JSX.Element | null => {

	const ctxt = useContext(ContexteFormationFlat)

	const aLePar = (aa : MotClefFlat_t) => {
		const date = new Date(aa.updated_at.pivot+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by.pivot}`
	}

	const aLeParObj = (aa : MotClefFlat_t) => { // à utiliser avec précaution : updated_at et updated_by doivent exister
		const date = new Date(aa.updated_at?.obj+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by?.obj}`
	}

	const lstItems = motsclefs?.map(
		(elt, ix) => {
			return (<li key={ix} className={ elt.validite === undefined ? "text-muted" : ""}>
				<LigneBilingueFlat texte={ elt.texteMC } langue={ctxt.langue}/>
				<span className="text-info small">
					&nbsp;{ elt.updated_by.obj && `édité ${aLeParObj(elt)} ; `}
					{ elt.validite !== null
						? (elt.updated_by.pivot) ? "affecté " + aLePar(elt) : ""
						: ""
					}
				</span>
			</li>)
		}
	)

	return <Card.Body>
		<h3>Mots clés</h3>
		<ul className="mb-0">{lstItems}</ul>
	</Card.Body>
}
