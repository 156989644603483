import React from "react"
import { LangueFlat } from "./langue-flat"
import { TravailFlat } from "./travail-flat"
import { ElementFlat_t } from "../imprimable"

interface Props {
	element: ElementFlat_t
}

/**
 * FactuelFlat
 * 
 * Objet React de manipulation (affichage/édition) des données factuelles d'un élément
 * 
 * @returns JSX.Element | null 
 */
export const FactuelFlat = ( { element }: Props): JSX.Element | null => {

	const factuel = element.details?.factuel
	const chargeable = ! element.listes?.length

	return <>
		{ chargeable && factuel?.cplDuree && <TravailFlat cplDuree={factuel?.cplDuree} /> }
		{ chargeable && <LangueFlat modAnglais={element.details?.modalite?.anglais ?? false}
			factAnglais={factuel?.anglais ?? false}
		/>}
	</>
}
