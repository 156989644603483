import React from "react"
import { createRoot } from "react-dom/client"
import "./index.scss"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"

const root = createRoot(
	document.getElementById("root") as HTMLElement
)
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
// StrictMode ajoute des vérifications en phase de dev.

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
