import React from "react"
import { useContext } from "react"
import { Card, Row } from "react-bootstrap"
import { DetailsEtpFlat } from "./details-etp-flat"
import { DetailsFlat } from "./details-flat"
import { ListesAvecTabsFlat, ListesSansTabsFlat } from "./listes-flat"
import { ElementFlat_t, EtapeFlat_t, ContexteFormationFlat  } from "./imprimable"
import { Bilingue_t } from "../utilities/bilingue"

interface Props {
	annee: number
	element: ElementFlat_t | EtapeFlat_t 
}

export const ElpFlat = ({ element, annee }: Props): JSX.Element | null => {
	const ctxt = useContext(ContexteFormationFlat)

	let border = null
	switch (element.nature) {
	case "période":
		border = "primary" ; break
	case "module" :
		border = "danger" ; break
	case "matière":
		border = "success" ; break
	default :
		border = "dark"
	}

	const listlength = element.listes?.length ?? 0
	const typeListes: string = element.listes
		? element.listes.reduce(
			(t: string,l: { typLse: string }) => {
				if (t == "X" || l.typLse == "X") return "X"
				if (t == "F" || l.typLse == "F") return "F"
				return t
			},"O"
		) : "O"

	const lib = (element.nature == "étape") ? element.notice?.libelle : element.details?.factuel?.libelle
	const bilingue = (texte: Bilingue_t) => (ctxt.langue == "en" && texte?.en) ? texte.en : texte?.fr
	const etiquette = (
		<h2>
			{ 
				((lib?.libelleLong) 
					? bilingue(lib.libelleLong)
					: (lib?.libelleCourt)
						? bilingue(lib.libelleCourt)
						: element.lic
				) + " "
			}
			({element.nature} {element.code})
			{
				(element.nature) == "matière"
					? " coefficient "+ element.nbCrd
					: (" "+element.nbCrd+" crédits.")
			}
		</h2>
	)

	const details = (
		<div>
			<Row className="mt-2">
				{ element.nature == "étape"
					? <DetailsEtpFlat etape={element} />
					: <DetailsFlat element={element} />
				}
			</Row>
		</div>
	)

	return (
		<Card border={border} className="border-5">
			<Card.Header className="container-fluid">
				<Row> {etiquette} </Row>
				{listlength > 0 && details}
			</Card.Header>

			{ (!element.listes || listlength == 0) ? <Card.Body> {details} </Card.Body> :
				(listlength == 1 && typeListes == "O")
					? <ListesSansTabsFlat listes={element.listes} annee={annee}/>
					: <ListesAvecTabsFlat listes={element.listes} annee={annee}/>
			}
		</Card>
	)
}
