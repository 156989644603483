import React from "react"
import { Toast } from "react-bootstrap"

interface ToastProps {
	erreur: Error | null
	onDismiss: (x: Error | null) => void
}

export const ErrorToast = ( {erreur, onDismiss}: ToastProps ):JSX.Element => <Toast
	style={{ position: "absolute", top: 0, right: 0, }}
	show={ erreur !== null } onClose={ () => onDismiss(null) }
>
	<Toast.Header><h4 className="mr-auto">Erreur</h4></Toast.Header>
	<Toast.Body className="alert-danger">{erreur?.message ?? "Erreur inconnue"}</Toast.Body>
</Toast>
