import React from "react"
import { useState, useEffect } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { ApogeeEtp_t, ChargesEtp_t, Charges_t } from "../apogee/apogee-types"
import { Libelle_t } from "./syllabus-types"
import { Bilingue_t } from "../utilities/bilingue"
import { ErrorToast } from "../utilities/error-toast"
import { TexteBilingue } from "../utilities/bilingue"
import { Charges } from "./charges"
import { SuiviRedaction } from "./suivi-redaction"
import { Titres } from "./titres"
import { set } from "lodash"
import { useSanctum } from "../sanctum/sanctum"

/**
 * NoticeEtp_tw, NoticeEtp_t
 * 
 * Objets de l'API issus de
 * base de données, table: syllabus.notices_etp
 * backend-sagesse/app/Modeles/Syllabus/NoticeEtp.php (fonction clean)
 * 
 * NoticeEtp_tw : version abrégée utilisée pour l'écriture
 * 
 */
interface NoticeEtp_tw {
	codVet: string
	commentaire: string | null
	libelle: Libelle_t | null
	contexte: Bilingue_t | null
}
interface NoticeEtp_t extends NoticeEtp_tw {
	codEtp: string
	validite: string
	updated_at: Date
	updated_by: string | null
	type: "noticeEtp"
}

/**
 * DescriptionEtp_tw, DescriptionEtp_t
 * 
 * Objets de l'API construits et reçus par
 * backend-sagesse/app/Http/Controllers/SyllabusController.php (fonction details / detailsEtp)
 * 
 * DescriptionEtp_tw : version abrégée utilisée pour l'écriture
 * 
 */
interface DescriptionEtp_tw {
	apogee?: null
	charges?: null
	notice?: NoticeEtp_tw | null
}
interface DescriptionEtp_t {
	apogee?: ApogeeEtp_t
	charges?: ChargesEtp_t
	notice?: NoticeEtp_t
}

/**
 * Props
 * 
 * Paramètres de la fonction DetailsEtp
 */
interface Props {
	annee: number
	code: string
	ouvert: boolean
}

/**
 * DetailsEtp
 * 
 * Objet React de manipulation (affichage/édition) des détails d'une étape
 * 
 * La modification est gérée directement par les éléments SuiviRedaction, Titres, TexteBilingue
 * 
 * @param annee : nombre
 * @param code : chaine ~ '[-A-Zx0-9]+'
 * @param ouvert : booléen contrôlant l'affichage ou non du contenu
 * @returns JSX.Element | null
 */
export const DetailsEtp = ({ annee, code, ouvert }: Props): JSX.Element | null => {
	const { apiAccess } = useSanctum()

	const [apogee, setApogee] = useState<ApogeeEtp_t | null>(null)
	const [charges, setCharges] = useState<Charges_t | null>(null)
	const [notice, setNotice] = useState<NoticeEtp_t | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)
	//const baseRoute = {code, annee}

	/**
	 * fetchDescription
	 * 
	 * fonction asynchrone d'écriture/lecture de la description de l'étape
	 * 
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 * 
	 * @param valeur : DescriptionEtp_tw
	 */
	const fetchDescription = async (valeur: DescriptionEtp_tw) : Promise<void> => {
		const url = `/api/details/etape/${code}/${annee}`
		try {
			const reponse = await apiAccess.post<DescriptionEtp_t>(url, valeur)
			if (reponse.data.apogee) setApogee(reponse.data.apogee)
			if (reponse.data.charges) setCharges(reponse.data.charges)
			if (reponse.data.notice) setNotice(reponse.data.notice)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}

	useEffect(() => {
		if (ouvert && (apogee === null || notice === null)) {
			fetchDescription( { apogee: null, notice: null, charges: null } )
		}
	}, [ouvert])

	if (apogee == null) return null

	const ModalHeader = <Modal.Header><Modal.Title className='text-primary'>
		Modification syllabus année {annee} - {annee + 1}<br />
			description étape {apogee.codVet}: {apogee.lic_etp}
	</Modal.Title></Modal.Header>

	const baseNotice: NoticeEtp_tw = {
		codVet: apogee.codVet,
		commentaire: null,
		libelle: null,
		contexte: null
	}

	/**
	 * updateFn
	 * 
	 * Générateur de callback de mise à jour
	 * 
	 * @param key 
	 * @returns 
	 */
	function updateFn<Type>(key: string) {
		return (value: Type) => {
			const notice: NoticeEtp_tw = set(Object.assign({}, baseNotice), key, value)
			fetchDescription({ notice } )
		}
	}

	return <Col>
		<Card >
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			{charges && <Charges charges={charges} />}
			<Row>
				<Col xs="12">
					<SuiviRedaction contenu={notice?.commentaire}
						header={ModalHeader} update={updateFn<string>("commentaire")} />
				</Col>
				<Col xs="12">
					<Titres libelle={notice?.libelle} lic={apogee.lic_etp}
						header={ModalHeader} update={updateFn<Libelle_t>("libelle")} />
				</Col>
				<TexteBilingue html titre="Contexte / Présentation de l'étape"
					tip="Il s'agit ici de présenter les grands objectifs de cette année d'études."
					texte={notice?.contexte ?? { "fr": "" }}
					header={ModalHeader} update={updateFn<Bilingue_t>("contexte")} />
			</Row>
		</Card>
	</Col>
}
