import React from "react"
import { useState } from "react"
import { Card, Form, Modal } from "react-bootstrap"
import { useForm, FormProvider } from "react-hook-form"
import { LigneBilingue } from "../utilities/bilingue"
import { EditButton, FormLibelle, OkCancel } from "../utilities/form-utilities"
import { Libelle_t } from "./syllabus-types"

interface PropsTitres {
	header?: JSX.Element
	libelle?: Libelle_t | null
	lic?: string
	update?: (value: Libelle_t) => void
}

export const Titres = ({header, libelle, lic, update} : PropsTitres): JSX.Element => {
	const [edit, setEdit] = useState(false)
	const onclickEdit = () => {
		//console.log({ libelle })
		setEdit(true)
	}
	const Formulaire = () => {
		const methodes = useForm<Libelle_t>({
			mode: "onChange",
			defaultValues: {
				libelleCourt: {
					fr: libelle?.libelleCourt?.fr ?? lic ?? "",
					en: libelle?.libelleCourt?.en ?? ""
				},
				libelleLong: {
					fr: libelle?.libelleLong?.fr ?? "",
					en: libelle?.libelleLong?.en ?? ""
				}
			}
		})
		const { handleSubmit, formState: { errors } }  = methodes
		const onSubmit = (value: Libelle_t) => {
			setEdit(false)
			//console.log(value)
			if (update) update(value)
		}
		const onclickCancel = () => setEdit(false)
		return <FormProvider {...methodes}>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<OkCancel  cancel={onclickCancel}/>
				<FormLibelle nom="libelleCourt.fr" labSz={4}
					label="Libellé court français" contrainte={ { required: true, maxLength: 25 } }
					feedback="le libellé court est requis et ne doit pas contenir plus de 25 caractères"
					invalide={!!errors.libelleCourt}
				>
					Ce champ <em>obligatoire</em> de 25 caractères se substitue au libellé court,
					défini dans les structures apogée, dont la typographie est parfois erratique.
				</FormLibelle>
				<FormLibelle nom="libelleLong.fr" labSz={12} label="Libellé long français">
					Ce champ <em>facultatif</em> sera imprimé dans le titre de l&apos;élément.
					S&apos;il est absent, le libellé court sera utilisé à sa place. Dans tous les cas,
					c&apos;est le libellé court qui apparaitra dans le sommaire du syllabus.
				</FormLibelle>
				<FormLibelle nom="libelleCourt.en" labSz={4}
					label="Libellé court anglais" contrainte={ { maxLength: 25 } }
					feedback="le libellé court anglais ne doit pas contenir plus de 25 caractères"
					invalide={!!errors.libelleCourt}
				>
					Ce champ <em>facultatif</em> de 25 caractères se substitue au libellé court,
					défini dans les structures apogée, lorsque la langue d&apos;affichage est l&apos;anglais.
					<em>S&apos;il n&apos;est pas défini il pourra l&apos;être par traduction automatique
					du libellé court français après la saisie du syllabus en français.</em>
				</FormLibelle>
				<FormLibelle nom="libelleLong.en" labSz={12} label="Libellé long anglais">
					Ce champ <em>facultatif</em> sera imprimé dans le titre de l&apos;élément.
					S&apos;il est absent, le libellé court sera utilisé à sa place. Dans tous les cas,
					c&apos;est le libellé court qui apparaitra dans le sommaire du syllabus.
				</FormLibelle>
				<OkCancel  cancel={onclickCancel}/>
			</Form>
		</FormProvider>
	}

	return <Card className="mx-1 mb-1">
		<Card.Body className='py-2'>
			<Card.Title className="text-primary mb-2">
				<EditButton onClick={onclickEdit}/>
				Titres
			</Card.Title>
			<Card.Text className="mb-1">
				<span className="text-primary">Libellé court&thinsp;: </span>
				<LigneBilingue texte={ { fr: libelle?.libelleCourt?.fr ?? lic ?? "-", en: libelle?.libelleCourt?.en } } />
			</Card.Text>
			<Card.Text>
				<span className="text-primary">Libellé long&thinsp;: </span>
				<LigneBilingue texte={ { fr: libelle?.libelleLong?.fr ?? "-", en: libelle?.libelleLong?.en } } />
			</Card.Text>
		</Card.Body>
		<Modal dialogClassName="modal-90w" show={edit} keyboard={false} backdrop="static">
			{ header }
			<Modal.Body>{ edit && <Formulaire /> }</Modal.Body>
		</Modal>
	</Card>
}
