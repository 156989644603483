import React from "react"
import { Card } from "react-bootstrap"
import { CplDuree_t } from "../../syllabus/syllabus-types"

interface PropsTravail {
	cplDuree?: CplDuree_t | null
}

export const TravailFlat = (
	{ cplDuree } : PropsTravail
): JSX.Element | null => {

	const CplDuree = () : JSX.Element | null => {
		switch (cplDuree?.type) {
		case "stage": return <Card.Text className="mb-0">
			Stage de {cplDuree.min} à {cplDuree.max} semaines. 
		</Card.Text>
		case "projet": return <Card.Text className="mb-0">
			Projet {cplDuree.dureeET > 0 ? `de ${cplDuree.dureeET} heure` : "sans heure"}
			{cplDuree.dureeET != 1 ? "s inscrites " : " inscrite "}
			à l&apos;emploi du temps
			{ cplDuree.fafp > 0
				? `, incluant ${cplDuree.fafp} heure` 
					+ ((cplDuree.fafp > 1) ? "s" : "") 
					+ " d'encadrement de projet"
				: ""
			}
			{ cplDuree.dureeHET > 0 
				? `, et nécessitant environ ${cplDuree.dureeHET} heure`
					+ ((cplDuree.dureeHET > 1) ? "s de " : " de ")
				: ", pour lequel il n'est pas habituellement nécessaire de prévoir du "
			} travail 
			{ cplDuree.dureeET > 0
				? " supplémentaire en dehors de ces heures."
				: " hors encadrement." 
			}
		</Card.Text>
		case "ens": return <Card.Text className="mb-0">
			Les activités pédagogiques en face à face présentiel inscrites à l&apos;emploi
			du temps
			{cplDuree.the > 0
				? ` nécessitent environ ${cplDuree.the} heure`
					+ ((cplDuree.the > 1) ? "s " : " ")
				: " ne nécessitent pas "
			} de travail supplémentaire hors encadrement.
		</Card.Text>
		case "val": return <Card.Text className="mb-0">
			Cette activité de validation de compétences 
			{ cplDuree.fafp > 0
				? ` nécessite ${cplDuree.fafp} heure`
					+ ((cplDuree.fafp > 1) ? "s " : " ")
				: " ne nécessite pas "
			}
			d&apos;activités pédagogiques en face à face présentiel 
			(entretien individuel, soutenance devant un jury, etc.) et
			{ cplDuree.the > 0
				? ` requiert habituellement ${cplDuree.the} heure`
					+ ((cplDuree.the > 1) ? "s " : " ")
				: " ne nécessite habituellement pas "
			}
			de travail supplémentaire hors encadrement.
		</Card.Text>
		}
		return null
	}

	return <Card.Body>
		<h3>Temps de travail</h3>
		<CplDuree />
	</Card.Body>
}

