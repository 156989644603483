import "./App.scss"
import React from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { PortailEcole } from "./ecole/portail-ecole"
import { Formation } from "./apogee/formation"
import { BrowserRouter } from "react-router-dom"
import { Sanctum, useSanctum } from "./sanctum/sanctum"
import { Breadcrumb, Container, Image, Nav, Navbar, Toast, ToastContainer } from "react-bootstrap"
import { Connexion } from "./sanctum/connexion"
import { Imprimable } from "./flat/imprimable"

// Les paramètres de connexion sont dans Sanctum
export const App = () : JSX.Element => {
	return <Sanctum>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<PortailEcole />} />
					<Route path="formation/:code" element={<Formation />} />
					<Route path="imprimable/:vet/:anneeU/en" element={<Imprimable langue="en"/>} />
					<Route path="imprimable/:vet/:anneeU" element={<Imprimable langue="fr"/>} />
					<Route path="*"
						element={
							<h1>Il n&apos;y a rien ici !</h1>
						}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	</Sanctum>
}

const Layout = () : JSX.Element => {
	const { authState, errorAck } = useSanctum()
	const param = useParams()
	const ctxt = useSanctum().envExec
	return <>
		<Navbar fixed="top" className="bg-secondary"  expand="sm">
			<Container fluid className="px-5">
				<Navbar.Brand href="/" className="text-white">
					<Image
						src="/favicon.ico"
						width="30"
						height="30"
						className="d-inline-block align-top"
						alt="Polytech logo"
						rounded
					/>&nbsp;
					Sagesse
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav>
						<Breadcrumb className="fs-4 mx-4" listProps={ {className: "mb-0"} }>
							<Breadcrumb.Item href="/"
								linkProps={ {className: "text-white"} }
							>École</Breadcrumb.Item>
							{ param.code && <Breadcrumb.Item
								linkProps={ {className: "text-white"} }
								href={`/formation/${param.code}`}>
								{ param.code }
							</Breadcrumb.Item> }
						</Breadcrumb>
					</Nav>
					<Nav>
						<h3 className="text-warning mb-0 mx-5">
							Version: {ctxt.infra}
						</h3>
					</Nav>
					<Nav className="ms-auto">
						<Connexion />
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
		<Container fluid className="mt-4 pt-5">
			<header className="ph-5 pb-3 mb-3 bg-light rounded-3" role="banner">
				<Environ />
				<h1 className="display-6 text-center">Polytech Montpellier</h1>
				<h1 className="text-center">Sagesse</h1>
				<hr className="mt-0 mb-1" />
			</header> 
			<main role="main">
				<Outlet />
				{
					authState.lasterror &&
					<ToastContainer className="p-3" position={"middle-end"}>
						<Toast onClose={errorAck} bg="danger">
							<Toast.Header closeButton={true}>
								<strong className="me-auto">Sagesse login</strong>
							</Toast.Header>
							<Toast.Body>{authState.lasterror}</Toast.Body>
						</Toast>
					</ToastContainer>
				}
			</main> 
		</Container>
	</>
}

const Environ = () : JSX.Element => {
	const ctxt = useSanctum().envExec
	return <p className="fs-6 lh-sm text-center fw-lighter fst-italic mb-2">
		BUILD: {ctxt.build_date} ;
		NODE_ENV: {ctxt.node_env} ;
		INFRA: {ctxt.infra} ;
		BASE_URL: {ctxt.base_url} ;
		API_URL: {ctxt.api_url}
	</p>
}
