import React from "react"
import { Table } from "react-bootstrap"
import { Charges_t } from "../../apogee/apogee-types"

interface PropsCharges {
	charges: Charges_t
}

export const ChargesFlat = ({ charges }: PropsCharges): JSX.Element | null => {

	const mapHeadToCharge: { [key in keyof Omit<Charges_t, "codAn">]: string} = {
		hCM: "CM",
		hCMTD: "CMTD",
		hTD: "TD",
		hTP: "TP",
		hTERRAI: "TERRAIN",
		hCNP: "CNP",
	}

	if (charges == null) return null

	const row = (prefixe: string, ch: Charges_t | null | undefined) =>
		(ch && <tr>
			<th scope="row">{prefixe}</th>
			{(Object.keys(mapHeadToCharge) as (keyof Charges_t)[]).map( (key, index) =>
				<th key={index} scope="col">{ch[key]}</th>
			)}
		</tr>)

	return <div className="mx-2 mt-2">
		<Table striped bordered hover size="sm">
			<thead><tr>
				<th scope="col">charge (élève)</th>
				{Object.values(mapHeadToCharge).map( (head, index) =>
					<th key={index} scope="col">{head}</th>
				)}
			</tr></thead>
			<tbody>
				{row("OSE/GSNP", charges)}
				{/*row('Corrections', cpl)*/}
			</tbody>
		</Table>
	</div>
}
