import React from "react"
import { useContext } from "react"
import { Card } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { CapaciteFlat_t, ContexteFormationFlat } from "../imprimable"

interface Props {
	capacites: Array<CapaciteFlat_t>
}

/**
 * Capacites
 * 
 * Objet React de manipulation (affichage/édition) des capacités associées à un élément
 * 
 * @returns JSX.Element | null 
 */
export const CapacitesFlat = ( { capacites }: Props): JSX.Element | null => {

	const ctxt = useContext(ContexteFormationFlat)

	const aLePar = (aa : CapaciteFlat_t) => {
		const date = new Date(aa.updated_at.pivot+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by.pivot}`
	}

	const aLeParObj = (aa : CapaciteFlat_t) => { // à utiliser avec précaution : updated_at et updated_by doivent exister
		const date = new Date(aa.updated_at?.obj+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by?.obj}`
	}

	const lstItems = capacites?.map(
		(elt, ix) => {
			return (<li key={ix} className={ elt.validite === undefined ? "text-muted" : ""}>
				<sup style={ {color: "red"} }>{(elt.rangCapCom?.toLocaleString("fr-FR", {minimumIntegerDigits: 2}) ?? "##")} </sup>
				<LigneBilingueFlat texte={ elt.libCapacite } langue={ctxt.langue}/>
				<span className="text-info small">
					&nbsp;{ elt.updated_by.obj && `éditée ${aLeParObj(elt)} ; `}
					{ elt.validite !== null
						? (elt.updated_by.pivot) ? "affectée " + aLePar(elt) : ""
						:  ""
					}
				</span>
			</li>)
		}
	)

	return <Card.Body>
		<h3>Capacités</h3>
		<ul className="mb-0">{lstItems}</ul>
	</Card.Body>
}
