import React from "react"
import {
	useState, useEffect, Reducer, useReducer,
	FocusEvent, useContext
} from "react"
import {
	Row, Col, Card, Modal, Form,
	InputGroup, Button, OverlayTrigger, Tooltip, Badge
} from "react-bootstrap"
import { nanoid } from "nanoid"
import { RotateCcw } from "react-feather"
import AsyncCreatableSelect from "react-select/async-creatable"
import { Placement } from "react-bootstrap/types"
import {
	CapaciteH_t, CapaciteH_tp, Capacite_t,
	DescElpRead_t, DescElpWrite_t
} from "./syllabus-types"
import { Bilingue_t, LigneBilingue } from "../utilities/bilingue"
import { EditButton, OkCancel } from "../utilities/form-utilities"
import { ErrorToast } from "../utilities/error-toast"
import { ContexteDetails } from "./details"
import { useSanctum } from "../sanctum/sanctum"

/**
 * Capacites
 * 
 * Objet React de manipulation (affichage/édition) des capacités associées à un élément
 * 
 * @returns JSX.Element | null 
 */
export const Capacites = (): JSX.Element | null => {
	const { apiAccess, authState: { user } } = useSanctum()

	const [capacites, setCapacites] = useState<CapaciteH_t[] | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)
	const [edit, setEdit] = useState(false)
	const ctxt = useContext(ContexteDetails)

	const onclickEdit = () => setEdit(true)

	//const compareFR = Intl.Collator("fr", {sensitivity: "base"})
	// const compareFRstrict = Intl.Collator("fr", {sensitivity: "variant"})
	// const compareEN = Intl.Collator("en", {sensitivity: "base"})
	const compareENstrict = Intl.Collator("en", {sensitivity: "variant"})

	/**
	 * fetchDescription
	 * 
	 * fonction asynchrone d'écriture/lecture d'une liste de capacités
	 * 
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 * 
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = async (valeur: DescElpWrite_t) : Promise<void> => {
		try {
			const reponse = await apiAccess.post<DescElpRead_t>(ctxt.url, valeur)
			const cap = reponse.data.capacites
			if (cap !== undefined) setCapacites(cap)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}

	useEffect(() => { fetchDescription( { capacites: null } ) }, [] )

	// useEffect(() => console.log({capacites}))

	const aLePar = (aa : CapaciteH_t) => {
		const date = new Date(aa.updated_at.pivot+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by.pivot}`
	}

	const aLeParObj = (aa : CapaciteH_tp) => { // à utiliser avec précaution : updated_at et updated_by doivent exister
		const date = new Date(aa.updated_at?.obj+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by?.obj}`
	}

	const TemoinModif = (  ) : JSX.Element =>
		<Badge bg="danger" pill
			className="position-absolute top-0 start-100 translate-middle border border-light"
		>
			&nbsp;
		</Badge>

	const Formulaire = () => {
		type Cap_t = { // représentation des capacités dans le formulaire
			id: string
			del: boolean
			neuf: boolean
			diffOrg: boolean
			propre: boolean // propre à l'élément édité
			org: CapaciteH_tp | null
			lib: Required<Bilingue_t>
			rangCC: number | null
			val: string
		}

		type PartialCapt = {
			[P in keyof Cap_t]+?:
			Cap_t[P] extends Bilingue_t
				? Partial<Bilingue_t>
				: Cap_t[P]
		}

		type action_t = { // actions du reducer
			type: string
			value?: PartialCapt
		}

		const capReducer = (state: Cap_t[], action: action_t): Cap_t[] => {
			// console.log( { action })
			const ident = action.value?.id
			function cap2capf(aa: CapaciteH_tp): Cap_t {
				return {
					id: nanoid(), del: false, neuf: false,
					diffOrg: false,
					propre: aa.codElp.includes(aa.troncCodElp),
					org: aa,
					lib: {fr: aa.libCapacite.fr, en: aa.libCapacite.en ?? ""},
					rangCC: aa.rangCapCom ?? null,
					val: aa.validite ?? "période inconnue"
				}
			}
			switch (action.type) {
			case "reset": return []
			case "init": // réinitialisation avec les capacités
				return capacites?.map<Cap_t>( cap2capf ) ?? []
			case "add": { // si org est set, il a la priorité
				const libfr = action.value?.org?.libCapacite ?? action.value?.lib?.fr
				if (libfr === undefined) return state // sortie rapide: on ne peut pas ajouter rien
				if (! state.find( aaf => aaf.lib.fr === libfr ) ) {
					// n'a pas été trouvé. On insère dans la liste (ordonnée).
					const org = action.value?.org
					const nouv : Cap_t = (org == null)
						? { id: nanoid(), del: false, neuf: true, diffOrg: true,
							propre: true, org: null,
							lib: { fr: action.value?.lib?.fr ?? "", en: action.value?.lib?.en ?? "" },
							rangCC: null,
							val: "l'année courante et les suivantes (a priori)" }
						: {...cap2capf(org), neuf: true }
					const point = state.findIndex( e =>
						e.rangCC === null
							? (nouv.rangCC !== null || e.lib.fr > nouv.lib.fr)
							: (nouv.rangCC !== null && e.rangCC > nouv.rangCC)
					)
					switch (point) {
					case -1: return [...state, nouv]
					case 0: return [nouv, ...state]
					default: return [...state.slice(0,point), nouv, ...state.slice(point)]
					}
				}
				// si l'objet a été trouvé, le add est interprété comme un undel
				return state.map( aaf => {
					if ( aaf.lib.fr === libfr ) return { ...aaf, propre: true, del: false }
					else return aaf
				})
			}
			case "bascule": {
				if (! ident) return state // id non indiqué...
				else return state.map( aaf => {
					if ( aaf.id === ident ) {
						const del = aaf.org?.brancheCodElp == null && ! aaf.del
						const propre = aaf.org?.brancheCodElp == null || ! aaf.propre
						return { ...aaf, propre: propre, del: del }
					} else return aaf
				})
			}
			case "maj": { // l'aaf est désigné par son id
				if (! ident) return state // id non indiqué...
				return state.map(  aaf => {
					if ( aaf.id !== ident ) return aaf
					else {
						const libfr = action.value?.lib?.fr ?? aaf.lib.fr
						const liben = action.value?.lib?.en ?? aaf.lib.en
						return { // Les champs non mis à jour sont nuls ou undefined (dans action.value)
							id: aaf.id,
							del: aaf.del,
							neuf: aaf.neuf,
							diffOrg: aaf.org===null
								|| libfr !== aaf.org.libCapacite.fr
								|| liben !== (aaf.org?.libCapacite.en ?? ""),
							propre: action.value?.propre ?? aaf.propre,
							org: aaf.org,
							lib: { fr: libfr, en: liben},
							rangCC: action.value?.rangCC ?? aaf.rangCC,
							val: action.value?.val ?? aaf.val
						}
					}
				})
			}
			default: return state
			}
		}

		const [caps, setCaps] = useReducer<Reducer<Cap_t[], action_t>>(capReducer, [])
		const actionReset: action_t = { type: "reset" }

		// useEffect(() => console.log({caps}))

		if (caps.length == 0 && capacites !== null && capacites.length != 0)
			setCaps({ type: "init" })

		const onclickCancel = () => {
			// console.log({ caps })
			setEdit(false)
			setCaps(actionReset)
		}

		const onclickValid = () => {
			const aa: Capacite_t[] = caps.filter(
				aaf => ! aaf.del && aaf.propre
			).map<Capacite_t>(
				(aaf) => ( { // tout est transféré tel qu'affiché, sauf la validité qui sera recalculée en backend
					libCapacite: {
						fr: aaf.lib.fr,
						en: (aaf.neuf || aaf.diffOrg) ? aaf.lib.en : undefined
					}
				} )
			)
			// console.log({ caps, aa })
			fetchDescription( { capacites: aa } )
			setEdit(false)
			setCaps(actionReset)
		}

		const suggestionsCap = async (inputValue: string) =>
			apiAccess.post<
					{fr: string; en?: string ; rang?: number; updated_at: Date; updated_by?: string}[]
				>("api/syllabus/capacite", { motif: inputValue })
				.then( res => res.data.map( x => { return {
					value: x ,
					label: (x.rang?.toLocaleString("fr-FR", {minimumIntegerDigits: 2})
						?? "##") + " / " + x.fr
				}}) )

		const Prologue = () => <div className="lh-sm">
			<p>
				Selon Philippe Meirieu (Apprendre... oui, mais comment, 1987) :
				une capacité est une &quot;[...] activité intellectuelle stabilisée et
				reproductible dans des champs divers de connaissance&quot;. Le terme
				capacité est souvent utilisé comme synonyme de &quot;savoir-faire&quot;.
			</p>
			<p>
				Indiquer ci-après quelques capacités
				spécifiques à l&apos;élement. Le sélecteur permet d&apos;ajouter des capacités en français.
				Les capacités numérotées ont été définies au niveau de l&apos;école. Elles sont non modifiables.
				On peut ajouter librement des capacités non numérotées et/ou modifier leur traduction anglaise.
			</p>
			<p>
				L&apos;affichage reprend les capacités déclarées sur les éléments constitutifs de
				l&apos;élément courant. Seules les capacités propres à l&apos;élément courant
				sont modifiables. Le bouton de couleur placé sous la capacité permet de supprimer
				ou réactiver une capacité déclarée. Le bouton <RotateCcw size={16}/> placé à droite permet
				d&apos;annuler les modifications faites à la traduction anglaise. Toutes les capacités
				modifiées dans le formulaire sont marquées par une pastille rouge sur le bouton.
				Enfin, les modifications
				ne deviennent définitives qu&apos;après validation.
			</p>
		</div>

		const deco = ( (aaf: Cap_t) =>  aaf.del
			? { textDecoration: "line-through red wavy" }
			: aaf.propre ? { textDecoration: "none" } : { textDecoration: "line-through yellow" }
		)

		interface PropsFormAA {
			aaf: Cap_t
		}

		const LibAcquisApp = ( { aaf }: PropsFormAA ) => (
			<Col>
				<OverlayTrigger placement={"top"} overlay={
					<Tooltip id={`tooltip-${aaf.id}`}>Défini pour {aaf.val}</Tooltip>
				} >
					<Form.Control value={
						((aaf.rangCC?.toLocaleString("fr-FR", {minimumIntegerDigits: 2}) ?? "##") + " / " + aaf.lib.fr)
					}  as="textarea" rows={1} readOnly style={ deco(aaf) }/>
				</OverlayTrigger>
			</Col>
		)

		interface PropsInputFormAA extends PropsFormAA {
			placeOvl: Placement
			nom: string
			valeur: string
			changeFn: ( a: string ) => PartialCapt
			reset: PartialCapt
			readonly: boolean
		}

		const InputAcquisApp = ( { aaf, placeOvl, nom, changeFn, valeur, reset, readonly }: PropsInputFormAA ) => {
			const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
				if (! readonly) setCaps( { type: "maj", value: { ...changeFn(event.target.value), id: aaf.id } } )
			}
			const resetVal = () => {
				if (! readonly) setCaps( { type: "maj", value: {...reset, id: aaf.id } } )
			}
			return <Col>
				<OverlayTrigger placement={placeOvl} overlay={ <Tooltip id={`${nom}-${aaf.id}`}>{nom}</Tooltip> }>
					<InputGroup>
						<Form.Control as="textarea" defaultValue={valeur} onBlur={ onBlur } placeholder={nom}
							readOnly={readonly} rows={1}
							style={ deco(aaf) }/>
						<RotateCcw
							style={ {display: "flex", flexDirection: "column", justifyContent: "center" } }
							size={16} className="btnSyllabus ml-2" onClick={ resetVal }
						/>
					</InputGroup>
				</OverlayTrigger>
			</Col>
		}

		interface MarqueSiModifProps {
			obj: Cap_t
		}

		const MarqueSiModif = ( {obj}: MarqueSiModifProps ) : JSX.Element | null => {
			if (obj.neuf) { if (obj.del) return null }
			else if ( ! obj.del
				&& (((obj.org?.validite ?? null) !== null) == obj.propre)
				&& compareENstrict.compare((obj.org?.libCapacite.en ?? ""), obj.lib.en) == 0
			) return null
			return <TemoinModif />
		}

		return <>
			<h1 className="mt-2">Capacités</h1>
			<Prologue />
			<OkCancel cancel={onclickCancel} valid={onclickValid}/>
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<Form>
				{caps && caps.map((cc) => (
					<Row key={`kw${cc.id}`} className="my-2 p-1 border border-primary rounded">
						<Col>
							<Row>
								<LibAcquisApp aaf={cc}/>
								<InputAcquisApp aaf={cc}
									nom="Capability"
									placeOvl="top"
									changeFn={ (v: string) => ({lib: { en: v }}) }
									valeur={cc.lib.en ?? ""}
									reset={ { lib: { en: cc.org?.libCapacite.en ?? ""} } }
									readonly= {cc.del || cc.rangCC !== null || !cc.propre}
								/>
							</Row>
							{cc.org?.updated_by?.obj && <p className="text-info small my-0">
								Édité {aLeParObj(cc.org)}
							</p>}
							<Row className="mx-0 mt-1"><Col>
								<Button className="px-2 py-0 position-relative"
									type="button"
									variant={ cc.propre && ! cc.del
										? "success"
										: cc.org?.brancheCodElp ? "warning" : "secondary"
									}
									onClick={() => setCaps({type: "bascule", value: {id: cc.id}})}
								>
									{ cc.org?.brancheCodElp
										? ( cc.propre
											? `Propre à l'élément ${ctxt.code} et propagée de `
											: "Seulement propagée de ") + cc.org.brancheCodElp.join(", ")
										: ( cc.del
											? `Propre à l'élément ${ctxt.code} mais effacée`
											: `Propre à l'élément ${ctxt.code}` )
									}
									<MarqueSiModif obj={cc} />
								</Button></Col>
							</Row>
						</Col>
					</Row>
				) ) }
			</Form>
			<AsyncCreatableSelect cacheOptions defaultOptions loadOptions={suggestionsCap}
				onCreateOption={(inputValue: string) => // il faudra ajouter ici un "êtes-vous sûr ?"
					setCaps({type: "add", value: {lib: {fr: inputValue }}})}
				onChange={(kw) => { if (kw && "value" in kw)
					setCaps( {
						type: "add",
						value: {
							org: {
								libCapacite: {
									fr: kw.value.fr,
									en: kw.value.en
								},
								rangCapCom: kw.value.rang ?? null,
								troncCodElp: ctxt.code,
								codElp: [ ctxt.code ],
								brancheCodElp: null,
								caractere: "O",
								updated_at: {obj: kw.value.updated_at, pivot: kw.value.updated_at },
								updated_by: {obj: kw.value.updated_by ?? null, pivot: null }
							}
						}
					} )
				} }
				className="mb-2 mt-2"/>
			<OkCancel cancel={onclickCancel} valid={onclickValid}/>
		</>
	}

	const lstItems = capacites?.map(
		(elt) => {
			const famille = elt.brancheCodElp ?? elt.codElp
			return (<li key={elt.idCapacite} className={ elt.validite === undefined ? "text-muted" : ""}>
				<sup style={ {color: "red"} }>{(elt.rangCapCom?.toLocaleString("fr-FR", {minimumIntegerDigits: 2}) ?? "##")} </sup>
				<LigneBilingue texte={ elt.libCapacite } />
				<span className="text-info small">
					&nbsp;{ elt.updated_by.obj && `éditée ${aLeParObj(elt)} ; `}
					{ elt.validite !== null
						? elt.brancheCodElp === null
							? (elt.updated_by.pivot) ? "affectée " + aLePar(elt) : ""
							: ("propre à l'élément " + elt.troncCodElp
								+ ((elt.updated_by.pivot) ? ` (affectée ${aLePar(elt)})` : "")
								+ " et propagée de ")
						: elt.brancheCodElp === null ? "" : "propagée de "
					}
					{elt.brancheCodElp && famille.join(", ")}
				</span>
			</li>)
		}
	)

	if (((lstItems?.length ?? 0) === 0 && (user ?? null) === null)) return null

	return <Row><Col xs="12">
		<ErrorToast erreur={erreur} onDismiss={setErreur} />
		<Card className="mx-1 mb-1">
			<Card.Header className="text-primary h5">
				<EditButton onClick={onclickEdit}/>Capacités
			</Card.Header>
			<Card.Body className="py-2">
				<ul className="mb-0">{lstItems}</ul>
			</Card.Body>
			<Modal dialogClassName="modal-90w" show={edit} keyboard={false} backdrop="static">
				{ ctxt.header }
				<Modal.Body>
					{ edit && <Formulaire /> }
				</Modal.Body>
			</Modal>
		</Card>
	</Col></Row>
}
