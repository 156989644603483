import React from "react"
import { ElementFlat_t } from "./imprimable"
import { AcquisApprentissageFlat } from "./syllabus/acquis-apprentissage-flat"
import { CapacitesFlat } from "./syllabus/capacites-flat"
import { ChargesFlat } from "./syllabus/charges-flat"
// import { CompetencesFlat } from "./syllabus/competences-flat"
import { FactuelFlat } from "./syllabus/factuel-flat"
import { MotsClefsFlat } from "./syllabus/mots-clefs-flat"
import { NoticeFlat } from "./syllabus/notice-flat"


export interface DetailsContextFlat_t {
	url: string
	annee: number
	code: string
}

interface Props {
	element: ElementFlat_t
}

export const DetailsFlat = ({ element }: Props): JSX.Element | null => {

	if (element.details == null) return null

	return <>
		{element.charges && <ChargesFlat charges={element.charges} />}
		<NoticeFlat  notice={element.details.notice} />
		<FactuelFlat element={element} />
		{element.details.capacites && <CapacitesFlat capacites={element.details.capacites} />}
		{element.details.motsClefs && <MotsClefsFlat motsclefs={element.details.motsClefs} />}
		{element.details.acquisApp && <AcquisApprentissageFlat acquisapp={element.details.acquisApp} />}
		{/* <CompetencesFlat /> */}
	</>
}
