import React from "react"
import { useContext } from "react"
import { Card } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import Table from "react-bootstrap/Table"
import ListGroup from "react-bootstrap/ListGroup"
import { LigneBilingue } from "../../utilities/bilingue"
import { AcquisAppFlat_t, ContexteFormationFlat } from "../imprimable"

interface Props {
	acquisapp: Array<AcquisAppFlat_t>
}
/**
 * AcquisApprentissageFlat
 * 
 * Objet React de manipulation (affichage/édition) des acquis d'apprentissage
 * associés à un élément
 * 
 * @returns JSX.Element | null 
 */
export const AcquisApprentissageFlat = ( { acquisapp }: Props ): JSX.Element | null => {

	const ctxt = useContext(ContexteFormationFlat)

	const aLePar = (aa : AcquisAppFlat_t) => {
		const date = new Date(aa.updated_at.pivot+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by.pivot}`
	}

	const aLeParObj = (aa : AcquisAppFlat_t) => { // à utiliser avec précaution : updated_at et updated_by doivent exister
		const date = new Date(aa.updated_at?.obj+"Z")
		return `à ${date.toLocaleTimeString("fr-FR")} le ${date.toLocaleDateString("fr-FR")} par ${aa.updated_by?.obj}`
	}

	const widthCol = (val: number) => ({width: val + "%"})

	const lstRows = acquisapp?.map((aa,index) => {
		return <tr key={index}>
			<th scope="row" className="text-center py-2">{index+1}</th>
			<td className="list-group p-1">
				<ListGroup className="p-1">
					<ListGroup.Item className="p-1">
						<LigneBilingueFlat texte={ aa.libAcquisApp } langue={ctxt.langue}/>
						{aa.updated_by.obj && <p className="text-info small my-0">
							Édité  {aLeParObj(aa)}
						</p>}
					</ListGroup.Item>
					{aa.mcc &&
						<ListGroup.Item className="p-1">
							<span className="text-muted">Preuve d&apos;apprentissage attendue</span><br/>
							<LigneBilingueFlat texte={ aa.mcc } langue={ctxt.langue}/>
						</ListGroup.Item>
					}
				</ListGroup>
				<p className="text-info small my-0 mx-2">
					{aa.validite !== null
						? (aa.updated_by.pivot) ? "Affecté " + aLePar(aa) : ""
						: ""
					}

				</p>
			</td>
		</tr>
	})

	// reste à élaguer

	return <Card.Body>
		<h3>Acquis d&apos;apprentissage</h3>
		<Table hover size="sm"  className="my-0">
			<colgroup>
				<col style={widthCol(5)}/>
				<col style={widthCol(95)}/>
			</colgroup>
			{/* <thead className="thead-light">
				<tr>
					<th scope="col" className="text-center">#</th>
					<th scope="col" className="text-left">Libellé</th>
				</tr>
			</thead> */}
			<tbody>{lstRows}</tbody>
		</Table>
	</Card.Body>
}
