import React from "react"
import { Card } from "react-bootstrap"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { Liste_t } from "./apogee-types"
import { Elp } from "./elp"

interface PropsListe {
	annee: number
	listes: Liste_t[]
}

interface PropsHeader {
	lst: Liste_t
}

const ListHeader = ({ lst }: PropsHeader) => {
	switch (lst.typLse) {
	case "O" : return (
		<Card.Header>
			{lst.codLse}: Liste obligatoire
		</Card.Header>
	)
	case "X" : return (
		<Card.Header>
			{lst.codLse}: Liste obligatoire à choix, sélectionner {
				(lst.nbMinElp == lst.nbMaxElp)
					? "exactement " + lst.nbMinElp + ((lst.nbMinElp < 2) ? " élément " : " éléments ")
					: "de " + lst.nbMinElp + " à " +  lst.nbMaxElp + " éléments "
			} de premier niveau dans la liste.
		</Card.Header>
	)
	}
	return (
		<Card.Header>
			{lst.codLse}: Liste d&apos;éléments facultatifs
		</Card.Header>
	)
}

export const ListesAvecTabs = ({listes, annee}: PropsListe): JSX.Element => (
	<Card.Body>
		<Tabs id="listes" className="mx-0" variant="pills" mountOnEnter>
			{listes.map((lst) => (
				<Tab key={lst.codLse} eventKey={lst.codLse} title={lst.codLse + ": " + lst.licLse}
					tabClassName="btn btn-light">
					<Card>
						<ListHeader lst={lst} />
						<Card.Body>
							<Tabs id="elements" className="mx-0" variant="pills" mountOnEnter>
								{lst.elements.map((elp) => (
									<Tab key={elp.code} eventKey={elp.code} title={elp.code + ": " + elp.lic}>
										<Elp annee={annee} nature={elp.nature} code={elp.code} />
									</Tab>))}
							</Tabs>
						</Card.Body>
					</Card>
				</Tab>))}
		</Tabs>
	</Card.Body>
)

export const ListesSansTabs = ({listes, annee}: PropsListe): JSX.Element => (
	<Card.Body>
		<Tabs id="elements" className="mx-0" variant="pills" mountOnEnter>
			{listes.map((lst) => (
				lst.elements.map((elp) => (
					<Tab key={lst.codLse + "-" + elp.code} eventKey={lst.codLse + "-" + elp.code}
						title={elp.code + ": " + elp.lic}>
						<Elp annee={annee} nature={elp.nature} code={elp.code} />
					</Tab>))
			))}
		</Tabs>
	</Card.Body>
)