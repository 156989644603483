import React from "react"
import { useContext } from "react"
import { Card } from "react-bootstrap"
import { LigneBilingueFlat } from "../bilingue-flat"
import { ContexteFormationFlat } from "../imprimable"

export interface AnglaisFlat_t {
	modalite: boolean
	factuel: boolean
}

interface PropsLangue {
	modAnglais: boolean
	factAnglais: boolean
}

export const LangueFlat = ({modAnglais, factAnglais} : PropsLangue): JSX.Element => {

	const ctxt = useContext(ContexteFormationFlat)

	// si terminal, cplDuree, anglais et modalites réclament un formulaire particulier.
	// mis en attente

	const libAnglais = (() => {
		if (modAnglais)
			return { "en": "This course is taught in English",
				"fr": "Cet enseignement est donné en anglais"}
		if (factAnglais) return {
			"en": "This course is taught in French, but can be taught in English",
			"fr": "Cet enseignement est donné en français, "
					+"mais il peut être donné en anglais"
		} ; else return {
			"en": "This course is taught in French",
			"fr": "Cet enseignement est donné en français"
		}
	}) ()

	return <Card.Body>
		<h3>Langue</h3>
		<LigneBilingueFlat texte={ libAnglais } langue={ctxt.langue}/>
	</Card.Body>

}
