import React from "react"
import { useState, useEffect, useContext } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { set } from "lodash"
import { ApogeeElp_t } from "../apogee/apogee-types"
import {
	DescElpRead_t, DescElpWrite_t,
	Factuel_t, Factuel_tw, CplDuree_t, 
	Libelle_t, Modalite_t, Modalite_tw
} from "./syllabus-types"
import { ErrorToast } from "../utilities/error-toast"
import { Titres } from "./titres"
import { SuiviRedaction } from "./suivi-redaction"
import { ContexteDetails } from "./details"
import { useSanctum } from "../sanctum/sanctum"
import { Anglais_t, Langue } from "./langue"
import { Travail } from "./travail"

/**
 * Factuel
 * 
 * Objet React de manipulation (affichage/édition) des données factuelles d'un élément
 * 
 * @returns JSX.Element | null 
 */
export const Factuel = (): JSX.Element | null => {
	const { apiAccess, authState: { user } } = useSanctum()

	const [apogee, setApogee] = useState<ApogeeElp_t | null>(null)
	const [factuel, setFactuel] = useState<Factuel_t | null>(null)
	const [modalite, setModalite] = useState<Modalite_t | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)
	const ctxt = useContext(ContexteDetails)

	useEffect(() => console.log({ apogee, factuel, modalite }))

	/**
	 * fetchDescription
	 * 
	 * fonction asynchrone d'écriture/lecture des données factuelles
	 * 
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 * 
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = async (valeur: DescElpWrite_t) : Promise<void> => {
		try {
			const reponse = await apiAccess.post<DescElpRead_t>(ctxt.url, valeur)
			if (reponse.data.apogee !== undefined) setApogee(reponse.data.apogee)
			if (reponse.data.factuel !== undefined) setFactuel(reponse.data.factuel)
			if (reponse.data.modalite !== undefined) setModalite(reponse.data.modalite)
		}
		catch(err: unknown) { if (err instanceof Error) setErreur(err)}
	}

	useEffect(() => {
		fetchDescription( { factuel: null, modalite: null, apogee: null } )
	}, [])

	if (apogee == null) return null

	const baseFactuel: Factuel_tw = {
		//		codElp: apogee.cod_elp,
		//		validite: `[${ctxt.annee},)`,
		commentaire: null,
		libelle: null,
		cplDuree: null,
		anglais: false
	}

	/**
	 * updateFn
	 * 
	 * Générateur de callback de mise à jour
	 * 
	 * @param key 
	 * @returns 
	 */
	function updateFn<Type>(key: string) {
		return (value: Type) => {
			const factuel: Factuel_tw = set(Object.assign({}, baseFactuel), key, value)
			fetchDescription({ factuel })
		}
	}

	function updateAnglais(valeur: Anglais_t) {
		const factuel: Factuel_tw = set(Object.assign({}, baseFactuel), "anglais", valeur.factuel)
		const modalite: Modalite_tw = { anglais: valeur.modalite }
		fetchDescription({ factuel, modalite })
	}

	// si terminal, cplDuree, anglais et modalites réclament un formulaire particulier.
	// mis en attente

	/*
		Support pour la saisie des temps de travail (cplDurée)
			const eltDuree = (label: string, nom: string) =>
				<Form.Group as={Col} controlId={`form${nom}`}>
					<Form.Label className='text-primary text-right'>{label}</Form.Label>
					<Form.Control as='input' type='number' step={0.5}
						name={nom} ref={register}/>
				</Form.Group>
		Une partie devra basculer du côté des charges...
			return <FormProvider {...methodes}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<OkCancel  cancel={onclickCancel}/>
					{terminal && <Card className="px-3 pt-3 pb-0 mb-3">
						<Card.Title>Charges présentielles</Card.Title>
						<Row xs={5} className="align-items-end">
							{eltDuree(`Cours Magistral (${charges?.hCM ?? 0}h dans Apogee)`, "hCM")}
							{eltDuree(`Cours / TD (${charges?.hCMTD ?? 0}h dans Apogee)`, "hCMTD")}
							{eltDuree(`Travaux Dirigés (${charges?.hTD ?? 0}h dans Apogee)`,"hTD")}
							{eltDuree(`Travaux Pratiques (${charges?.hTP ?? 0}h dans Apogee)`,"hTP")}
							{eltDuree(`Sorties Terrain (${charges?.hTERRAI ?? 0}h dans Apogee)`,
								"hTERRAI")}
						</Row>
						<Card.Title>
							Charges non présentielles
							(incompatibles avec des charges présentielles)
						</Card.Title>
						<Row xs={1}>
							{eltDuree(`CNP par élève: (${charges?.hCNP ?? 0}h dans Apogee)`, "hCNP",)}
						</Row>
						<Card.Title>Temps élève</Card.Title>
						<Row xs={2}  className="align-items-end">
							{eltDuree(
								"Temps de travail (h) non encadré inscrit à l'ET: (Projet)", "hProjet")}
							{eltDuree("Temps de travail (h) hors encadrement estimé:", "hThe")}
						</Row>
					</Card> }
					<Card className="px-3 pt-3 pb-0 mb-3">
						<Card.Title>Titres</Card.Title>
						<TitresForm libelle={factuel?.libelle} />
					</Card>
					{ terminal && <Card className="px-3 pt-3 pb-0 mb-3">
						<Card.Title>Langue</Card.Title>
						<Form.Check type='switch' id="switchAnglais" name="anglais" ref={register}
							label="Cet enseignement peut-être donné en anglais"
						/>
						<Form.Check type='switch' id="switchModalite" name="modaliteAnglais"
							ref={register}  disabled={!anglais}
							label={`Pour ${annee}-${annee+1} cet enseignement est donné en anglais`}
						/>
					</Card>}
					<OkCancel  cancel={onclickCancel}/>
				</Form>
			</FormProvider>
		Localisation des informations:
			defaultValues: {
				commentaire: description?.factuel?.commentaire ?? "",
				libelleCourt_fr: description?.factuel?.libelle?.libelleCourt?.fr
								?? description?.apogee?.lic_elp ?? "",
				libelleLong_fr: description?.factuel?.libelle?.libelleLong?.fr,
				libelleCourt_en: description?.factuel?.libelle?.libelleCourt?.en,
				libelleLong_en: description?.factuel?.libelle?.libelleLong?.en,
				hProjet: description?.factuel?.cplDuree?.hProjet ?? 0,
				hThe: description?.factuel?.cplDuree?.hThe ?? 0,
				anglais: description?.factuel?.anglais ?? false,
				modaliteAnglais: description?.modalite?.anglais ?? false
			}
	*/

	const coeur = (): JSX.Element => <Row>
		<ErrorToast erreur={erreur} onDismiss={setErreur} />
		<Col xs="12">
			<SuiviRedaction contenu={factuel?.commentaire}
				header={ctxt.header} update={updateFn<string>("commentaire")} />
		</Col>
		<Col xs="12">
			<Titres libelle={factuel?.libelle} lic={apogee.lic_elp}
				header={ctxt.header} update={updateFn<Libelle_t>("libelle")} />
		</Col>
		{ apogee?.chargeable && <Col xs="12">
			<Travail header={ctxt.header} nature={apogee.lic_nel}
				cplDuree={factuel?.cplDuree}
				update={updateFn<CplDuree_t>("cplDuree")} // à modifier
			/>
		</Col>}
		{ apogee?.chargeable && <Col xs="12">
			<Langue header={ctxt.header} 
				modAnglais={modalite?.anglais ?? false}
				factAnglais={factuel?.anglais ?? false}
				update={updateAnglais} // à modifier
			/>
		</Col>}
	</Row>

	return user ?
		<Card className="m-1">
			<Card.Header>
				<span className="text-primary h4">Données factuelles et suivi de rédaction</span>
				{factuel && factuel.updated_by && <span className="text-info small">
					&nbsp;(édité {
						( () => {
							const date = new Date(factuel.updated_at+"Z")
							return `à ${date.toLocaleTimeString("fr-FR")} le `
							+ date.toLocaleDateString("fr-FR")
							+ " par " + factuel.updated_by
						})()
					})
				</span>}
			</Card.Header>
			<Card.Body className="m-1 p-0 mt-0">
				{coeur()}
			</Card.Body>
		</Card>
		: coeur()
}
