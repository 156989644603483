import React from "react"
import { useState } from "react"
import { ToggleButton, ToggleButtonGroup, Card, Form, Modal, Row, Col } from "react-bootstrap"
import { LigneBilingue } from "../utilities/bilingue"
import { EditButton, OkCancel } from "../utilities/form-utilities"

export interface Anglais_t {
	modalite: boolean
	factuel: boolean
}

interface PropsLangue {
	header?: JSX.Element
	modAnglais: boolean
	factAnglais: boolean
	update?: (value: Anglais_t) => void
}

export const Langue = ({header, modAnglais, factAnglais, update} : PropsLangue): JSX.Element => {
	const [edit, setEdit] = useState(false)
	const onclickEdit = () => {
		//console.log({ libelle })
		setEdit(true)
	}

	// si terminal, cplDuree, anglais et modalites réclament un formulaire particulier.
	// mis en attente

	const libAnglais = (() => {
		if (modAnglais)
			return { "en": "This course is taught in English",
				"fr": "Cet enseignement est donné en anglais"}
		if (factAnglais) return {
			"en": "This course is taught in French, but can be taught in English",
			"fr": "Cet enseignement est donné en français, "
					+"mais il peut être donné en anglais"
		}
		return {
			"en": "This course is taught in French",
			"fr": "Cet enseignement est donné en français"
		}
	}) ()

	const Formulaire = () => {
		const [valeur, setValeur] = useState<string>(
			modAnglais ? "A" : factAnglais ? "F+A" : "F"
		)
	
		const onSubmit = ( ) => {
			setEdit(false)
			const val : Anglais_t = {
				modalite: valeur === "A",
				factuel: valeur !== "F"
			}
			if (update && (val.modalite != modAnglais
				|| val.factuel != factAnglais
			)) update(val)
		}
		const onclickCancel = () => setEdit(false)

		// const handleChange = (val: string) => setValeur(val)

		const langues = [
			{ name: "Anglais", value: "A" },
			{ name: "Français, mais anglais possible", value: "F+A" },
			{ name: "Français seulement", value: "F" },
		]

		return <>
			<h1 className="mt-2">Langue</h1>
			<Form>
				<OkCancel  cancel={onclickCancel} valid={onSubmit}/>
				<Row className="my-3">
					<Col xs={12} className="text-center">
						<h5>
							Les enseignements de cette matière
							sont dispensés en
						</h5>
					</Col>
					<Col xs={12} className="text-center">
						<ToggleButtonGroup
							name="langue"
							type="radio"
							value={valeur}
							onChange={setValeur}
						>
							{langues.map((langue, idx) => (
								<ToggleButton
									key={idx}
									id={`langue-${idx}`}
									value={langue.value}
									variant="outline-primary"
								>
									{langue.name}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Col>
				</Row>
				<OkCancel cancel={onclickCancel} valid={onSubmit}/>
			</Form>
		</>
	}

	return <Card className="mx-1 mb-1">
		<Card.Body className='py-2'>
			<Card.Title className="text-primary mb-2">
				<EditButton onClick={onclickEdit}/>
				Langue
			</Card.Title>
			<Card.Text className="mb-1">
				<LigneBilingue texte={ libAnglais } />
			</Card.Text>
		</Card.Body>
		<Modal show={edit} keyboard={false} backdrop="static">
			{ header }
			<Modal.Body>{ edit && <Formulaire /> }</Modal.Body>
		</Modal>
	</Card>
}
